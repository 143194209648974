import Web3 from 'web3';
import { CONTRACT_ABI, CONTRACT_ADDRESS, CONTRACT_CHAIN } from './config';
import './App.css';
import Home from './pages/Home';
import Asteroids from './modules/Asteroids';
import Asteroid from './modules/Asteroid';
import Wallet from './pages/Wallet';
import Milestones from './pages/Milestones'
import Manifesto from './pages/Manifesto';
import Whitepaper from './pages/Whitepaper';
import Faq from './pages/Faq';
import Promote from './pages/Promote';

import { useEffect, useState, useMemo } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import KickStarter from './pages/KickStarter';

import Random from './pages/Random';

import Header from './menu/header';
import ScrollToTopBtn from './menu/ScrollToTop';
import Footer from './components/footer'

import { createGlobalStyle } from 'styled-components';
const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;



function App() {  

  const [items,setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [itemsCount, setItemsCount] = useState();
  const [connected,setConnected]=useState(2);
  const [account, setAccount] = useState();
  const [alert, setAlert]=useState({ 'show': false, title: 'test', 'message': "", 'variant': "danger" });

  const web3 = new Web3(Web3.givenProvider);
  const contract = new web3.eth.Contract(CONTRACT_ABI,CONTRACT_ADDRESS);

  function launchAlert(message, variant, wait=3000) {
    setAlert(alert => ({ 'show': true, 'message': message, 'variant': variant }));
    setTimeout( () => clearAlert(), wait);
  }

  function clearAlert() {
    setAlert(alert => ({ 'show': false, title: '', 'message': "", 'variant': "" }));
  }

  if (typeof window.ethereum !== 'undefined') {
    window.ethereum.on('chainChanged', (chainId) => {activateWallet();});
    window.ethereum.on('accountsChanged', (accounts) => {activateWallet();});
  }

  async function activateWallet() {
    try {
      const accounts = await web3.eth.requestAccounts();
      const chainId = await web3.eth.getChainId();
      setAccount(accounts[0]);
      if(chainId === CONTRACT_CHAIN) {
        setConnected(1);        
      }
      else {
        launchAlert('Please connect to Ethereum Blockchain','info');
        setConnected(5);
      }
    }
    catch(error) {
      switch(error.code) {
        case -32002:
          setConnected(4);
          break;
        case 4001:
        default:
          setConnected(0);
      }
      launchAlert(error.message,'danger');
      console.log(error.code);
      console.log(error.message);
    }
  }

  function conectar() {
    setConnected(4);
    activateWallet();
  }

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const query=useQuery();
 


  function addToList(des, name, value, id) {
    let lItems=JSON.parse(localStorage.getItem('items'))
    if(lItems == null) lItems=[];
    let stored = JSON.parse(localStorage.getItem('stored'));
    if (stored == null) stored = [];
    if (stored.indexOf(id) === -1) {
      stored = [...stored, id];
      const newItem = { 'designation': des, 'name': name, 'value': value, 'id': id };
      const sItems = [...lItems, newItem];
      localStorage.setItem("items", JSON.stringify(sItems));
      localStorage.setItem("stored", JSON.stringify(stored));
      setItems(sItems);
      setItemsCount(stored.length);

      setTotal(total => total + value);
      launchAlert(des+' added to list', 'success');
    }
    else
      launchAlert(des + " already in list", 'warning')
  }

  function clearList() {
    setItems([]);
    setItemsCount();
    localStorage.removeItem('items');
    localStorage.removeItem('stored');
  }

  function removeFromList(id) {
    getItems();
    let stored = JSON.parse(localStorage.getItem('stored'));
    if (stored == null)
      stored = [];
    const idx = stored.indexOf(id);
    if (idx === -1)
      launchAlert("Item not found",'danger',3000);
    else {
      let sItems = items;
      sItems.splice(idx, 1);
      stored.splice(idx, 1);
      setItemsCount(stored.length);
      localStorage.setItem("items", JSON.stringify(sItems));
      localStorage.setItem("stored", JSON.stringify(stored));
      setItems(sItems);
      setTotal(0);
      if (sItems) {
        sItems.map(item => (
          setTotal(total => total + item.value)
        ));
      }
    }
  }

  function getItems() {
    let lItems = JSON.parse(localStorage.getItem('items'));
    if(lItems==null) lItems=[];
    setItems(lItems);
    if(lItems.length) setItemsCount(lItems.length) 
    console.log(lItems);     
  }

  useEffect(() => {
    async function load() {
      if (typeof window.ethereum === 'undefined') setConnected(3);
      else {
        //activateWallet();
      }
    };      
    getItems();
    load();      
},[]);

useEffect(() => {
    setTotal(0);
    if (items) {                
        items.map(item => (
            setTotal(total => total + item.value)
        ))         
    }       
},[items]);  
  
  return (
    <>
     <div className="wrapper">
      <GlobalStyles />
            
      <Header status={connected} connect={conectar} pending={itemsCount} alert={alert} clear={clearAlert}/>
      
      <Routes>
        <Route path="/" element={<Home web3={web3}/>}/>
        <Route path="/:referer/" element={<Home web3={web3} />} />
        <Route path="/kickstart" element={<KickStarter connected={connected} conectar={conectar} account={account} contract={contract} web3={web3} alert={launchAlert} />} />
        <Route path="/asteroids" element={<Asteroids alert={launchAlert}/>} />
        <Route path="/claimed" element={<Asteroids minted="1" alert={launchAlert} />} />
        <Route path="/faq" element={<Faq web3={web3} />} />
        <Route path="/milestones" element={<Milestones web3={web3}/>} />
        <Route path="/manifesto" element={<Manifesto web3={web3}/>} />
        <Route path="/whitepaper" element={<Whitepaper web3={web3}/>} />
        <Route path="/promote" element={<Promote connected={connected} conectar={conectar} account={account} contract={contract} web3={web3} alert={launchAlert} />} />
        <Route path="/wallet" element={<Wallet  connected={connected} conectar={conectar} account={account} contract={contract} items={items} refresh={getItems} total={total} add={addToList} remove={removeFromList} clear={clearList} web3={web3}  alert={launchAlert}/>} />
        <Route path="/recent" element={<Wallet  connected={connected} conectar={conectar} account={account} contract={contract} items={items} refresh={getItems} total={total} add={addToList} remove={removeFromList} clear={clearList} web3={web3}  alert={launchAlert} block={query.get("block")}/>} />
        <Route path="/asteroid" element={<Asteroid id={query.get("id")} items={items} total={total} add={addToList} remove={removeFromList} clear={clearList} connected={connected} conectar={conectar} account={account} alert={launchAlert}/>}/>
        <Route path="/transfer" element={<Asteroid id={query.get("id")}  connected={connected} conectar={conectar} account={account} contract={contract} web3={web3} alert={launchAlert} transfer="1"/>} />
        <Route path="/random" element={<Random connected={connected} conectar={conectar} account={account}  contract={contract} web3={web3}  alert={launchAlert} />} />
      </Routes>
      <ScrollToTopBtn />
      <Footer />        
    </div>
    </>
  );
};
export default App;
