import {useEffect} from "react";

function NeedConnect(props) {
    useEffect(() => {
        
        props.conectar();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    switch(Number(props.connected)) {
        case 1:
            return (
               <></>
            )
                 
        default:
            return (
                <div className="needConnect d-flex"> 
                    <div className="py-4">
                        <h5 className="text-light d-md-inline px-2">Please connect your wallet to continue</h5>
                        <button className="btn-main d-inline btn-main fs-6 text rounded-0" onClick={() => props.conectar()}>Connect your wallet</button>
                    </div>
                </div>
            )
    }
}
export default NeedConnect;
