import { Link } from "react-router-dom";

function AsteroidList(props) {
    function goView(id) {
        window.location.assign('/asteroid?id='+id);
    }    
    function goTransfer(id) {
        window.location.assign('/transfer?id='+id);
    }

    if(props.asteroids.length >0 ) {
        return (
            <div className="row" >
            {props.asteroids.map(asteroid => (
                <div key={asteroid.baseid} className="col-lg-3 col-md-6 col-sm-6 col-xs-12 rounded mb-4 p-2">
                <div  className="card shadow rounded">

                    <img src={asteroid.thumbnail_ipfs} className="card-img-top" alt={"Orbit for "+asteroid.principal}/>
                    <div className="card-body border-top-0 rounded-bottom bg-light">
                        <h5 className="card-title" style={{wordWrap: 'nowrap'}}>{asteroid.principal} - { asteroid.name}</h5>
                        <p className="card-text">Discovered in {asteroid.discovered}.<br/>
                        Orbit type: {asteroid.orbit}.<br/>
                        {asteroid.valued !== '-' ? (
                            <> Valued {asteroid.valued} USD.</>
                        ): (
                            <> Not yet valued.</>
                        )}
                        <br/>
                        <strong>ABC Category: {asteroid.ranking}</strong><br/>
                        {props.claimedlist !=="1" && (
                            <>
                            <strong>Claim Price: </strong> {asteroid.claimprice} ETH
                            </>
                        )}
                                                
                        </p>
                        { props.randomlist !=="1" && (
                            asteroid.claimed==='YES' ?
                            (
                                <>
                                { props.pending ==="1" ? (
                                    <button className="btn-black fs-6 text rounded-0 border-dark float-end" onClick={()=>{props.remove(asteroid.baseid)}}>Remove</button>
                                ): (
                                    <button id={asteroid.baseid+'view'} className="btn-view text inline rounded-0" onClick={()=>{goView(asteroid.baseid);}}>View</button>
                                )}                                
                                {props.claimedlist==="1" && (
                                    <>
                                    <button id={asteroid.baseid+'transfer'} className="btn-transfer inline text rounded-0" onClick={()=>{goTransfer(asteroid.baseid);}}>Transfer</button>
                                    </>
                                )}
                                
                                </>
                                
                            ):
                            (
                                <>
                                { props.pending ==="1" ? (
                                    <button className="btn-black fs-6 text rounded-0 border-dark float-end" onClick={()=>{props.remove(asteroid.baseid)}}>Remove</button>
                                ): (
                                    <Link className="btn float-start" to={`/asteroid?id=${asteroid.baseid}`}>View & Claim</Link>      
                                )}
                                </>
                                  
                            )
                            
                        )}

                         
                                            
                    </div>
                </div>
                </div>
            ))}
            </div>
        )
    }
    else {
        return (
            <></>
        )
    }
    
}
export default AsteroidList;