import axios from "axios";
import { useEffect, useState } from 'react';
import NeedConnect from "../modules/NeedConnect";
import WaitModal from "../modules/WaitModal";
import { APIURL } from '../config';

function KickStarter(props) {

    const expectedBlockTime = 1000; 
    const [wait,setWait]=useState({"show":false});
    const [ksData,setKsData]=useState({});
    const [ksBalance,setKsBalance]=useState(0);
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    function kickStartFund() {
        let referer=localStorage.getItem('referer');
        const value=Number(document.getElementById('add').value);
        axios.post("https://"+APIURL+"/kickstart/fund", {
            wallet: props.account,
            value: value,
            referer: referer,
        })
        .then(function(response) {
            const data=response.data.data;
            kickstart(data.referer,data.cost,data.expiration,data.msgHash,data.signature)
        })
        .catch(function(error) {
            props.alert(error.response.data.message,"danger");
        });
    }    

    function refreshKickstartData() {
        if(props.connected===1) {
            
            props.contract.methods.kickStartThreshold().call(function (err, res) {
                if (err) {
                    props.alert(err.message,"danger");          
                }
                else {
                    setKsData({"level":res[0],"upto":props.web3.utils.fromWei(res[1], 'ether')});
                }                                
              })              

            
            props.contract.methods.kickStarters(props.account).call(function (err, res) {
                if (err) {
                    props.alert(err.message, "danger");                                 
                }
                else {
                    setKsBalance(props.web3.utils.fromWei(res, 'ether'));
                }                
              });
            };        
    }

    async function kickstart(referer,cost,expiration,msgHash,signature) {        
        try {
            props.alert('Please wait while check Gas Price', 'info', 6000);
            props.contract.methods.kickstart(referer,expiration,msgHash,signature).estimateGas({ from: props.account, value: cost}, function(error, estimatedGas) {
                if(error!==null) {
                    let errore = error.toString() 
                    if (errore.indexOf('Internal JSON-RPC error.') > -1) {
                          errore = errore.replace('\n', '').replace("Error: ", '').replace('Internal JSON-RPC error.', '')
                          error = JSON.parse(errore)
                    }                    
                    console.log(error);
                    props.alert(error.message,'danger',6000);
                }
                else {
                    props.contract.methods.kickstart(referer,expiration,msgHash,signature).send({from: props.account ,value: cost},async (error, transactionHash) => {
                        if(error!==null) {
                            let errore = error.toString() 
                            if (errore.indexOf('Internal JSON-RPC error.') > -1) {
                                  errore = errore.replace('\n', '').replace("Error: ", '').replace('Internal JSON-RPC error.', '')
                                  error = JSON.parse(errore)
                            }
                            console.log(error);
                            props.alert(error.message,'danger',6000);
                        }
                        else {
                            let transactionReceipt = null;
                            const message="Please wait until network confirm your transaction";
                            setWait(wait => ({"show":true, "title":"Please wait for Transaction Confirm","message": message}))
                            while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
                                try {
                                    transactionReceipt = await props.web3.eth.getTransactionReceipt(transactionHash);
                                    await sleep(expectedBlockTime);
                                }
                                catch(error) {
                                    console.log("An error occured", error) 
                                    props.alert(error.message,"danger");
                                }
                                
                            }
                            setWait(wait => ({"show":false}));
                            if(transactionReceipt.status===true) {
                                const message='Transaction Hash: '+transactionReceipt.transactionHash+' Gas used'+transactionReceipt.gasUsed.toString();
                                props.alert(message,"success");
                                refreshKickstartData();
                            }
                            else {
                                const message='Transaction with Hash: '+transactionReceipt.transactionHash+' Gas used'+transactionReceipt.gasUsed.toString()+' return false';
                                props.alert(message,"warning");
                            }
                        }
                    });
                       
                        
                }

            })
        }
        catch(error) {           ;
            console.log(error);
            props.alert(error.message,"danger");
        }

    }
    
    useEffect(() => {
        refreshKickstartData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props]); 


    async function kickStartTransfer() {        
        try {
            const to=document.getElementById('to').value;
            const amount=props.web3.utils.toWei(document.getElementById('amount').value, 'ether');
            props.alert('Please wait while check Gas Price', 'info', 6000);
            props.contract.methods.kickStartTransfer(to,amount).estimateGas({ from: props.account}, function(error, estimatedGas) {
                if(error!==null) {
                    let errore = error.toString() 
                    if (errore.indexOf('Internal JSON-RPC error.') > -1) {
                        errore = errore.replace('\n', '').replace("Error: ", '').replace('Internal JSON-RPC error.', '')
                        error = JSON.parse(errore)
                    }

                    console.log(error);
                    props.alert(error.message,'danger',6000);
                }
                else {
                    props.contract.methods.kickStartTransfer(to,amount).send({from: props.account},async (error, transactonHash) => {
                        if(error!==null) {
                            let errore = error.toString() 
                            if (errore.indexOf('Internal JSON-RPC error.') > -1) {
                                errore = errore.replace('\n', '').replace("Error: ", '').replace('Internal JSON-RPC error.', '')
                                error = JSON.parse(errore)
                            }
                            console.log(error);
                            props.alert(error.message,'danger',6000);
                        }
                        else {
                            let transactionReceipt = null;
                            const message="Please wait until network confirm your transaction";
                            setWait(wait => ({"show":true, "title":"Please wait for Transaction Confirm","message": message}))
                            while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
                                try {
                                    transactionReceipt = await props.web3.eth.getTransactionReceipt(transactonHash);
                                    await sleep(expectedBlockTime);
                                }
                                catch(error) {
                                    console.log("An error occured", error) 
                                    props.alert(error.message,"danger");
                                }
                                
                            }
                            setWait(wait => ({"show":false}));
                            if(transactionReceipt.status===true) {
                                const message='Transaction Hash: '+transactionReceipt.transactionHash+' Gas used'+transactionReceipt.gasUsed.toString();
                                props.alert(message,"success");
                                refreshKickstartData();
                            }
                            else {
                                const message='Transaction with Hash: '+transactionReceipt.transactionHash+' Gas used'+transactionReceipt.gasUsed.toString()+' return false';
                                props.alert(message,"warning");
                            }
                        }
                    });
                       
                        
                }

            })
        }
        catch(error) {           ;
            console.log(error);
            props.alert(error.message,"danger",8000);
        }

    }
    return (
        <>
            <WaitModal show={wait.show} title={wait.title} message={wait.message} />
            <section className="jumbotron no-bg l-vh pb-4" style={{backgroundImage: `url(${'./img/bg_about.jpg'})`, backgroundRepeat: 'no-repeat'}}>
                <div className="container" >
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="text-left mt-5">
                                <h1 className="fw-bold text-black mb-0 mt-4 text-white">KickStarter Program</h1>
                            </div>         
                            <div className="rounded p-4 bg-black bg-opacity-50 overflow-scroll mb-5 mt-4 text-light" style={ {minHeight: '52vh'}}>
                            <p className="lead">The KickStarter Program rewards the enthusiasm of the early adopters of the Asteroid Belt Club proposal by multiplying the amount of ETH raised according to a sliding scale of rewards depending on the amounts previously raised.</p> 
Thus, the first 50 ETH collected by the program are multiplied by 8, the next 50 ETH by 4, the next 100 ETH by 3 and the last 300 by 2. <br/><br/>The minimum amount to participate in the program is ETH 0.05.<br/><br/>
Those who have accessed the Kickstarter program will have an account from which they can deduct the cost of their property claims, and even transfer all or part of their balance to a third party.<br/><br/>
The program will end when 500 ETH have been collected, but we will continue to allow deposits without multiplier, to top up small balances and not leave them unused. <br/><br/>
Please use your KickStart funds as soon as possible, once we reach 550000 mint, the funds can no longer be used. Be careful!<br/><br/>

                            </div>
                            <div className="row justify-content-center">
                            <div className="col-md-12 rounded pb-4">        
                                {props.connected !==1 ? (
                                <NeedConnect connected={props.connected} conectar={props.conectar} account={props.account}/>
                                ):(
                                <>  
                                <div className="spacer-10"></div>
                                <div className="row align-items-center text-white ">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-9 text-center">
                                        <div className="row">
                                            <div className="col-md-6 text-center">
                                                <p className="kickstat">Current Reward: {ksData.level}x</p>
                                            </div>
                                            <div className="col-md-6 text-center">
                                                <p className="kickstat">Remain: {ksData.upto} ETH</p>
                                            </div>
                                        </div>
                                        <strong>Your current balance: <strong>{ksBalance} ETH</strong></strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <form className="row justify-content-center" onSubmit={e => { e.preventDefault(); }}>
                                        <div className="col-md-2">
                                            <label className="text-white fw-bold text">Add:</label>  
                                            <input className="form-control bg-black bg-opacity-50 rounded-0" type="text" name="add" id="add" />   
                                        </div>              
                                        <div className="col-md-3">
                                            <label style={{display:'block'}}>&nbsp;</label>   
                                            <button className="btn-main fs-6 text rounded-0" onClick={() => kickStartFund()}>Add ETH</button>
                                        </div>                               
                                    </form>
                                </div>
                            { ksBalance > 0 && (
                                <>
                                <div className="spacer-20"></div>
                                <div className="spacer-20"></div>
                                <div className="row text-center text-white">
                                    <h5 className="text-white">You can transfer your balance here</h5>                                    
                                </div>
                                <div className="row">
                                    <form className="row justify-content-center text-white" onSubmit={e => { e.preventDefault(); }}>
                                    <div className="col-md-3">
                                            <label>To Wallet:</label>  
                                            <input className="form-control bg-black bg-opacity-50 rounded-0" type="text" name="to" id="to" />   
                                        </div> 
                                        <div className="col-md-2">
                                            <label>Amount:</label>  
                                            <input className="form-control bg-black bg-opacity-50 rounded-0" type="text" name="amount" id="amount" />   
                                        </div>              
                                        <div className="col-md-3">
                                            <label></label>    
                                            <button className="btn-black fs-6 text rounded-0 border-0" onClick={() => kickStartTransfer()}>Transfer</button>
                                        </div>                               
                                    </form>
                                </div>
                                </>
                            )}
                            </>

                            )}
                        </div>
                    </div>
                </div>
                </div>
                <div className="col-md-5 xs-hide"></div>
                    </div>
            </section>
        </>
    );



}
export default KickStarter;