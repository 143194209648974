

function BannerKickStarter() {
    return (
        <div className="container"  style={ {minHeight: '20vh'}}>
            <div className="row align-items-center">
                <div className="col-12 text-center">
                <span style={{cursor:'pointer'}}onClick={()=> window.location.href="/kickstart"}>
                    <img alt="Join now to Kickstarter program!" style={{maxWidth:'100%'}} src="/img/bannerkick2.gif"/>
                </span>
                </div>
            </div>
        </div>
    )
}
export default BannerKickStarter;
