import Stats from "../components/Stats.js";
import {faqabc, faqcoll, faqrand, faqkick, faqstrat, faqbelt, faqans, faqign} from "../faq.js";
import parse from 'html-react-parser';



function Faq(props) {

  return (
  <div>
      <section className="jumbotron breadcumb  no-bg l-vh pb-4" style={{backgroundImage: `url(${'./img/bg_faq.jpg'})`, backgroundRepeat: 'no-repeat'}}>
        <div className="container" >
          <div className="row align-items-center">
            <div className="col-md-10">
              <div className="spacer-single"></div>
              <div className="text-center">
                <h1>Frequently Asked Questions</h1>
               </div> 
                <div className="col-md-12 rounded pt-2 bg-black bg-opacity-50 overflow-scroll mb-5 mt-4 text-white"  style={ {minHeight: '66vh'}}>
                    
                  <ul className="m-4 p-4 text-white">
                  <h3 style={{'color':'#fff'}}>The Club</h3>
                  {faqabc.map(ask => (
                        <>
                        <li key={ask.nu}><strong>{ask.ask}</strong><br/>{parse(ask.ans)}<br/></li>
                        <br/>
                        </>
                    ))}                    
                  </ul>
                  <ul className="m-4 p-4 text-white">
                    <h3 style={{'color':'#fff'}}>NFT Collection</h3>
                  {faqcoll.map(ask => (
                        <>
                        <li key={ask.nu}><strong>{ask.ask}</strong><br/>{parse(ask.ans)}<br/></li>
                        <br/>
                        </>
                    ))}                    
                  </ul>
                  <ul className="m-4 p-4 text-white">
                    <h3 style={{'color':'#fff'}}>Random Claim</h3>
                  {faqrand.map(ask => (
                        <>
                        <li key={ask.nu}><strong>{ask.ask}</strong><br/>{parse(ask.ans)}<br/></li>
                        <br/>
                        </>
                    ))}                    
                  </ul>
                  <ul className="m-4 p-4 text-white">
                    <h3 style={{'color':'#fff'}}>KickStarter Program</h3>
                  {faqkick.map(ask => (
                        <>
                        <li key={ask.nu}><strong>{ask.ask}</strong><br/>{parse(ask.ans)}<br/></li>
                        <br/>
                        </>
                    ))}                    
                  </ul>
                  <ul className="m-4 p-4 text-white">
                    <h3 style={{'color':'#fff'}}>Strategic Reserve</h3>
                  {faqstrat.map(ask => (
                        <>
                        <li key={ask.nu}><strong>{ask.ask}</strong><br/>{parse(ask.ans)}<br/></li>
                        <br/>
                        </>
                    ))}                    
                  </ul>
                  <ul className="m-4 p-4 text-white">
                    <h3 style={{'color':'#fff'}}>The Belter's Day</h3>
                  {faqbelt.map(ask => (
                        <>
                        <li key={ask.nu}><strong>{ask.ask}</strong><br/>{parse(ask.ans)}<br/></li>
                        <br/>
                        </>
                    ))}                    
                  </ul>
                  <ul className="m-4 p-4 text-white">
                    <h3 style={{'color':'#fff'}}>Asteroid Naming Service</h3>
                  {faqans.map(ask => (
                        <>
                        <li key={ask.nu}><strong>{ask.ask}</strong><br/>{parse(ask.ans)}<br/></li>
                        <br/>
                        </>
                    ))}                    
                  </ul>
                  <ul className="m-4 p-4 text-white">
                    <h3 style={{'color':'#fff'}}>Asteroid Belt Club Igniter</h3>
                  {faqign.map(ask => (
                        <>
                        <li key={ask.nu}><strong>{ask.ask}</strong><br/>{parse(ask.ans)}<br/></li>
                        <br/>
                        </>
                    ))}                    
                  </ul>
                </div>
              
                
          </div>
        </div>
      </div>
      <Stats main="1" web3={props.web3}/>
      </section>
  </div>
);
  }
export default Faq;