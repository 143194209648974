import axios from "axios";
import { useEffect, useState } from 'react';
import Search from './Search';
import AsteroidList from "./AsteroidsList";
import { APIURL } from '../config';
import Launcher from "../components/Launcher";

function Asteroids(props) {
    const [asteroids, setAsteroids]=useState([]);
    const [paginator, setPaginator]=useState([0,1]);



    function getList(search,length,category,page) {
        let url="https://"+APIURL+"/data/asteroids?search="+search+"&length="+length+"&category="+category+"&page="+page;
        if(props.minted==="1") url=url+"&minted=1";
        axios.get(url)
        .then(function (response) {
            let prev=0;
            let next=2;
            if(page >1) {
              prev=page-1;
              next=page+1;
            }
            setPaginator([prev,next]);
            setAsteroids(response.data.data);
        })
        .catch(function(error) {
            console.log(error);
            props.alert(error.response.data.message,"danger");
        });

    };
    useEffect(() => {
        getList('',20,'',1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
      <section className="jumbotron no-bg l-vh pb-4" style={{backgroundImage: `url(${'./img/bg_join-claim.jpg'})`, backgroundRepeat: 'repeat', backgroundSize: 'contain', backgroundPosition: 'top'}}>
        <div className="container" >
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="spacer-single"></div>
              <div className="spacer-single"></div>
              <div className="text-left mt-5">
                <h1 className="fw-bold text-white mb-0 mt-4">
                  {props.minted==="1" ? <>Asteroids already claimed</> : <>Claim an asteroid</>}
                  
                  </h1>
                  <p className="leads fw-bold text-white"><Launcher /></p>
              </div>                
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 rounded py-4">        
                
                <Search search={getList} pages={paginator} />                       
                <AsteroidList asteroids={asteroids} claimedlist="0"/>

            </div>
        </div>
        </div>
    </section>
        
    );    
}
export default Asteroids;