import SliderMain from '../components/SliderMain';
import BannerKickStarter from '../components/bannerkickstarter';
import Stats from '../components/Stats';
import axios from "axios"
import { useParams } from 'react-router-dom';
import { APIURL } from '../config';

const Home= (props) => {

  const { referer } = useParams();
  if(referer !==undefined) {
      axios.get("https://"+APIURL+"/affiliate/check?code="+referer)
      .then(function (response) {
        localStorage.setItem('referer',referer);
      })
      .catch(function(error) {
        console.log(referer+" not found");
      });      
  } 

  return (
    <section className="jumbotron no-bg h-vh pb-4"  style={{backgroundImage: `url(${'./img/bg-shape5.jpg'})`, backgroundRepeat: 'no-repeat'}}>    
      <SliderMain/>
      <BannerKickStarter />
      <Stats main="1" web3={props.web3}/>
    </section>  
  )
};
export default Home;