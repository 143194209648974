import axios from "axios";
import { useEffect, useState } from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import NeedConnect from "../modules/NeedConnect";
import WaitModal from "../modules/WaitModal";
import Stats from "../components/Stats";
import {APIURL} from "../config";

function Promote(props) {
    const [refcode,setRefCode]=useState();
    const [status,setStatus]=useState(0);
    const [balance, setBalance]=useState(0);
    const [wait,setWait]=useState({"show":false});

    const expectedBlockTime = 1000;
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    
    const fadeInUp = keyframes`
        0% {
            opacity: 0;
            -webkit-transform: translateY(40px);
            transform: translateY(40px);
        }
        100% {
            opacity: 1;
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    `;    

    function check(account) {
        console.log(account)
        axios.get("https://"+APIURL+"/affiliate/check?wallet="+account)
        .then(function (response) {
            setStatus(response.status);
            setRefCode(response.data.data.referercode);
            updateBalance();
        })
        .catch(function(error) {
            console.log(error)
            setStatus(error.response.status)
        });

    };

    async function whithdrawal() {
        try {
            props.alert('Please wait while check Gas Price', 'info', 6000);
            props.contract.methods.referrerWidthraw().estimateGas({ from: props.account}, function(error, estimatedGas) {
                if(error!==null) {
                    const ErrMsg=JSON.parse(error.message.substring(24));
                    props.alert(ErrMsg.message,'danger');
                }
                else {
                    props.contract.methods.referrerWidthraw().send({from: props.account},async (error, transactonHash) => {
                        if(error!==null) {
                            props.alert(error.message,'danger');
                        } 
                        else {
                            let transactionReceipt = null;
                            const message="Please wait until network confirm your transaction";
                            setWait(wait => ({"show":true, "title":"Please Wait","message": message}))
                            while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
                                try {
                                    transactionReceipt = await props.web3.eth.getTransactionReceipt(transactonHash);
                                    await sleep(expectedBlockTime);
                                }
                                catch(error) {
                                    props.alert(error.message,'danger');
                                }
                                
                            }
                            setWait(wait => ({"show":false}));
                            if(transactionReceipt.status===true) {
                                const message='Transaction Hash: '+transactionReceipt.transactionHash+' Gas used'+transactionReceipt.gasUsed.toString();
                                props.alert(message,'success');
                                updateBalance();
                              }
                        }
                    });
                        
                        
                }

            })
        }
        catch(error) {           
            console.log(error)
            props.alert('Error claiming: ',error.message,'danger');
        }        

    }    

    function updateBalance() {
        props.contract.methods.referrerBalance(props.account).call(function (err, res) {
            if (err) {                
                console.log("An error occured", err)                
                return                
            }
            setBalance(props.web3.utils.fromWei(res, 'ether'));
        })
    }

    function registerAccount() {
        axios.post("https://api.asteroidbeltclub.com/affiliate/register", {
            wallet: props.account,
            email: document.getElementById('email').value
        })
        .then(function(response) {
            props.alert('Thanks for joining Belter\'s Day', 'success');
            setStatus(response.status);   
            setRefCode(response.data.data.referercode);
        })
        .catch(function(error) {
            props.alert(error.response.data.message, 'danger');
        });
    }

    useEffect(()=>{
        if(props.connected===1) check(props.account);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.account]);
    
    return (
        <>       
            <WaitModal show={wait.show} title={wait.title} message={wait.message} /> 
            <section className="jumbotron no-bg l-vh no-bottom" style={{backgroundImage: `url(${'./img/bg_join.jpg'})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                <div className="container" >
                    <div className="row align-items-center">
                    <div className="col-md-7">
                            <div className="text-left mt-5">
                                <h1 className="fw-bold text-black mb-0 mt-4 text-white">Help Us to promote</h1>
                                </div>        
                                <div className="rounded p-4 bg-black text-white bg-opacity-50 overflow-scroll mb-5 mt-4" style={ {minHeight: '54vh'}}>
                                <p className="lead">If you find the Asteroid Belt Club proposal innovative, attractive and believe it can make a positive difference in the future of humanity as a multiplanetary species, we would like to invite you to collaborate with its diffusion, joining the referral program and obtaining income derived from a 5% commission for each transaction that involves a direct payment from the referred user.</p>

                                The rules are absolutely transparent:<br/>
                                <ul>
                                    <li className="mt-1">Subscribing to the program generates a link that you can use to promote ABC on your website, favorite social network, among your acquaintances, etc. Please do not use unsolicited e-mail as a method of diffusion.
                                Whoever accesses the Club site by following your link will be identified as your referral. To do this we use the local storage of the browser, as we do not use cookies. The stored information DOES NOT EXPIRES; only if the user changes browser or deletes the contents of the local storage (for example, when closing an anonymous session) or if the user accesses by following a link published by another affiliate.<br/></li>
                                    <li className="mt-1">Each time this person performs a transaction involving the transfer of ETH from his wallet to the Club's smart contract, the commission is automatically deposited to your account in the contract itself. 
                                The amount of commissions you generate is immediately available and can be transferred to your wallet at any time. Please note that neither we nor anyone else can make a withdrawal unless it is to the wallet that was used at the time of signing up for the program. If you lose access to the wallet you will also lose the commissions you have generated and not withdrawn.</li>
                                <li className="mt-1">For security reasons, you will have one year to withdraw the funds, in case you do not do it, your balance will expire. The year starts to count from the moment you receive your first commission or after your last withdrawal, so we recommend you to make a withdrawal periodically. In case your balance expires, your links will still be valid and the year will start counting from your next commission received.</li>
                                </ul>
                            </div>
                    <div className="row justify-content-center">
                        <div className="col-md-12 rounded pb-4">        
                        {props.connected !==1 ? (
                            <NeedConnect connected={props.connected} conectar={props.conectar} account={props.account}/>
                        ):(
                        <> 
                            {status===200 && (
                                <>
                                <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
                                    <h3>Your referer code is {refcode}</h3>
                                    <p className="text-black">Use <strong>https://www.asteroidbeltclub.com/{refcode}</strong> as your promotion link.</p>
                                    <div className="spacer-10"></div>
                                        <form className="form-border row">                    
                                            <div className="col-md-4">
                                                <div className="field-set text-black fw-bold">
                                                    <label className="text-black fw-bold">Your Address</label>
                                                    <input type="text" name="address" id="address" className="form-control bg-dark rounded-0 text-white" value={props.account} disabled/>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="field-set text-black fw-bold">
                                                    <label className="text-black fw-bold">Balance</label>
                                                    <input type="text" name="balance" id="balance" className="form-control bg-dark rounded-0 text-white" value={balance}/>
                                                </div>
                                            </div>                                            

                                            <div className="col-md-6">
                                                <div className="field-set">
                                                    <label style={{display:'block'}}>&nbsp;</label>
                                                    <button style={{display:'inline', width: '50%'}} type="button" className="btn-main fs-6 text rounded-0 px-0" onClick={()=>{updateBalance()}}>Refresh</button>
                                                    <button style={{display:'inline', marginLeft: '5px', width: '45%'}} type="button" className="btn-black fs-6 text rounded-0 border-0 px-2" onClick={()=>{whithdrawal()}}>Whithdrawal</button>
                                                </div>
                                            </div>                                                                                 
                                        </form>
                                </Reveal>
                                </>
                            )}
                            {status===404 && (
                                <>
                                
                                    <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
                                        <h1><p className="text-black"><strong>Register to join</strong>.</p></h1>                                    
                                        <p className="lead text-black"><strong>Use a real email, it's important!!</strong>.</p>                                        
                                        <div className="rounded p-4 bg-black text-white bg-opacity-50 overflow-scroll mb-5 mt-4" style={ {minHeight: '18vh'}}>
                                            <p className="lead text-white ">
                                        We will send you an email when your account get new funds. Of course, at any time you can check your balance here. 
                                        We will also send you an email reminder 10 days before the end of your withdrawal period and another one the day before.<br></br>  
                                        We will never share your email with nobody, but for your saffety if you want to protect your identity use an empty wallet to register as Referrer.</p>
                                        <div className="spacer-10"></div>
                                        </div>
                                        <form className="form-border row">                    
                                            <div className="col-md-4">
                                                <div className="field-set text-white fw-bold">
                                                    <label className="text-black fw-bold">Your Address</label>
                                                    <input type="text" name="address" id="address" className="form-control bg-dark rounded-0 text-white" value={props.account} disabled/>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="field-set text-white fw-bold">
                                                <label className="text-black fw-bold">Email</label>
                                                    <input type="text" name="emal" id="email" className="form-control bg-dark rounded-0 text-white" placeholder="email@example.com"/>
                                                </div>
                                            </div>
                                            <div className="col-md-4 pull-right">
                                                <label style={{display:'block'}}>&nbsp;</label>
                                                <button type="button" className="btn-main fs-6 text rounded-0" onClick={()=>{registerAccount()}}>Register</button>
                                            </div>
                                        </form>
                                    </Reveal>
                                </>
                                
                            )}
                        </>
                        )}
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-md-5 xs-hide"></div>
                    </div>
                    <Stats promote="1" web3={props.web3} />
            </section>
           
        </>
    )
}
export default Promote;
