import NeedConnect from "../modules/NeedConnect";
import Collection from "../modules/Collection";
import Pending from "../modules/Pending";
import { useEffect, useState } from 'react';


function Wallet(props) {

    const [openMenu, setOpenMenu] = useState(false);
    const [openMenu1, setOpenMenu1] = useState(false);
    const [openMenu2, setOpenMenu2] = useState(false);

    const handleBtnClick = () => {
        setOpenMenu(true);
        setOpenMenu1(false);
        setOpenMenu2(false);
        document.getElementById("Mainbtn").classList.add("active");
        document.getElementById("Mainbtn1").classList.remove("active");
        document.getElementById("Mainbtn2").classList.remove("active");
    };
    const handleBtnClick1 = () => {
        setOpenMenu(false);
        setOpenMenu1(true);
        setOpenMenu2(false);            
        document.getElementById("Mainbtn").classList.remove("active");
        document.getElementById("Mainbtn1").classList.add("active");
        document.getElementById("Mainbtn2").classList.remove("active");
    };
    const handleBtnClick2 = () => {
        setOpenMenu(false);
        setOpenMenu1(false);
        setOpenMenu2(true);            
        document.getElementById("Mainbtn").classList.remove("active")
        document.getElementById("Mainbtn1").classList.remove("active");
        document.getElementById("Mainbtn2").classList.add("active");
    };                  

    useEffect(() =>{
        if(props.connected===1) {
            if(props.block === undefined) {
                if(props.items.length >0) handleBtnClick1();
                else handleBtnClick();
            }
            else {
                handleBtnClick2();                
            } 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.connected,props.block])
    
    return (
        <>
            <section className="jumbotron no-bg l-vh pb-4" style={{backgroundImage: `url(${'./img/bg_join-claim.jpg'})`, backgroundRepeat: 'repeat', backgroundSize: 'contain',backgroundPosition: 'center top'}}
            >
                <div className="container" >
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="text-left mt-5">
                            <h1 className="fw-bold text-black mb-0 mt-4 text-white">My Claims</h1>
                            </div>                
                    <div className="row">
                        <div className="col-md-12 pb-4">        
                            {props.connected !==1 ? (
                            <NeedConnect connected={props.connected} conectar={props.conectar} account={props.account}/>
                            ):(
                            <>  
                            <div className="spacer-10"></div>
                            <div className="de_tab">
                                <ul className="de_nav text-left pb-4">
                                    <li id='Mainbtn' className=""><span className="rounded-0 text" onClick={handleBtnClick}>Already owned</span></li>
                                    <li id='Mainbtn2' className=''><span className="text rounded-0" onClick={handleBtnClick2}>Recently claimed</span></li>
                                    <li id='Mainbtn1' className=''><span className="text rounded-0" onClick={handleBtnClick1}>Cart</span></li>
                                    

                                    
                                </ul>
                                <div className="de_tab_content">
                                {   openMenu1 && (
                                    <div className="tab-1 onStep fadeIn">
                                        <Pending refresh={props.refresh} remove={props.remove} account={props.account} connected={props.connected} items={props.items} clear={props.clear}  contract={props.contract} web3={props.web3} total={props.total} alert={props.alert} />                                                                         
                                    </div>
                                )}
                                {   openMenu && (
                                    <div className="tab-2 onStep fadeIn">
                                        <Collection  connected={props.connected} conectar={props.conectar} account={props.account} contract={props.contract}/>
                                    </div>
                                )}
                                {  openMenu2 && (
                                    <div className="tab-3 onStep fadeIn">
                                        <Collection connected={props.connected} conectar={props.conectar} account={props.account} contract={props.contract} block={props.block} useblock="1"/>
                                    </div>
                                )}
                                </div>                            
                            </div>                            
                            </>
                            )}
                        </div>
                    </div>
                </div>
                </div>
                <div className="col-md-5 xs-hide"></div>
                </div>
            </section>
        </>
    )
}
export default Wallet;