
function Search(props) {
    return (
        <>
        <div className="row g-3">
            <div className="col-auto">
                <label className="text-white fw-bold text">Search</label>
                <input className="form-control bg-dark rounded-0 text-white" type="text" id="search" placeholder="By Name, Id or Designation"/>
            </div>
            <div className="col-auto">
                <label className="text-white fw-bold text">Category</label>
                <select className="form-select bg-dark rounded-0 text-light select-search" name="category" id="category" defaultValue=""><option className="form-select bg-dark rounded-0 text-light select-search" value="">All Categories</option><option className="form-select bg-dark rounded-0 text-light select-search" value="0">Category 0</option><option className="form-select bg-dark rounded-0 text-light select-search" value="1" >Category 1</option><option className="form-select bg-dark rounded-0 text-light select-search" value="2">Category 2</option><option className="form-select bg-dark rounded-0 text-light select-search" value="3">Category 3</option><option className="form-select bg-dark rounded-0 text-light select-search" value="4">Category 4</option><option className="form-select bg-dark rounded-0 text-light select-search" value="5">Category 5</option></select>    
            </div>            
            <div className="col-auto">
                <label className="text-white fw-bold text">Get</label>
                <select className="form-select bg-dark rounded-0 text-light" name="length" id="length" defaultValue={20}><option className="form-select bg-dark rounded-0 text-light select-search" value="10">10</option><option className="form-select bg-dark rounded-0 text-light select-search" value="20" >20</option><option className="form-select bg-dark rounded-0 text-light select-search" value="50">50</option><option className="form-select bg-dark rounded-0 text-light select-search" value="100">100</option></select>    
            </div>
            <div className="col-auto">
                <label style={{display:'block'}}>&nbsp;</label>                
                <button className="btn-main fs-6 text rounded-0" type="button" onClick={()=>{props.search(document.getElementById('search').value,document.getElementById('length').value, document.getElementById('category').value,1);}}>Search</button>
            </div>
        </div>
        <div className="row justify-content-end">
            <div className="col-auto">
                <label style={{display:'block'}}>&nbsp;</label>                
                <button className="btn-main fs-6 text rounded-0" type="button" onClick={()=>{props.search(document.getElementById('search').value,document.getElementById('length').value, document.getElementById('category').value,props.pages[0]);}}><i className="fa fa-backward"></i></button>
                <button className="btn-main fs-6 text rounded-0" type="button" onClick={()=>{props.search(document.getElementById('search').value,document.getElementById('length').value, document.getElementById('category').value,props.pages[1]);}}><i className="fa fa-forward"></i></button>
            </div>                                         
        </div>
        </>
    )
}
export default Search;