import {Modal} from "react-bootstrap";

function WaitModal(props) {
    return (
        <Modal show={props.show} backdrop="static" keyboard={false} >
            <Modal.Header>
            <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.message}
            </Modal.Body>
        </Modal>
    );
  };
  export default WaitModal;