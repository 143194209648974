import { useState, useEffect } from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import axios from "axios";
import { APIURL } from '../config';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

function Stats(props) {
  const[data,setData]=useState({});
  const[status,setStatus]=useState(0);
  function getContractData() {
    axios.get("https://"+APIURL+"/data/contract")
    .then(function (response) {
      setStatus(response.status);
      let lData= response.data.data;
      console.log(lData);
      lData.totalsales=Number(props.web3.utils.fromWei(lData.totalsales, 'ether')).toFixed(2)
      lData.assets=Number(props.web3.utils.fromWei(lData.assets.toString(), 'ether')).toFixed(2)
      lData.refererspaid=Number(props.web3.utils.fromWei(lData.refererspaid, 'ether')).toFixed(2)
      lData.refererswidthrawn=Number(props.web3.utils.fromWei(lData.refererswidthrawn, 'ether')).toFixed(2)
      setData(lData);
    })
    .catch(function(error) {
      console.log(error);
      setStatus(error.response.status)
    });
  }

  useEffect(() => {
    getContractData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
  <div className="container pt-2">
    {status===200 && (
      <>
    { props.main === "1" && (
    <div className="row justify-content-md-center mt-3">
          <div className="col col-md-2 text-center border-end border-gray btn-degrated">
            <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
            <h6 className=""><span>Total Claims</span></h6>
            <h2>{data.claims}</h2>
            </Reveal>
          </div>
          <div className="col col-md-2 text-center border-end border-gray btn-degrated">
            <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
            <h6 className=""><span>Total Collected</span></h6>
            <h2>{data.totalsales} ETH</h2>
            </Reveal>
          </div>
          <div className="col col-md-2 text-center border-end border-gray btn-degrated">
            <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600} triggerOnce>
            <h6 className=""><span>ABC Funds</span></h6>
            <h2>{data.assets} ETH</h2>
            </Reveal>
          </div>
          <div className="col col-md-2 text-center border-end border-gray btn-degrated">
            <Reveal className='onStep' keyframes={fadeInUp} delay={900} duration={600} triggerOnce>
            <h6 className=""><span>Members</span></h6>
            <h2>{data.holders}</h2>
            </Reveal>
          </div>
    </div>
    )}
    { props.promote === "1" && (
      <div className="row align-items-center pb-5">
        <div className="col-md-6 text-center border-end border-gray btn-degrated">
          <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
          <h6 className="pt-2"><span className="text-black">Referers Earnings</span></h6>
          <h2>{data.refererspaid} ETH</h2>
          </Reveal>
        </div>
        <div className="col-md-6 text-center border-end border-gray btn-degrated">
          <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
          <h6 className="pt-2"><span className="text-black">Referers Withdrawals</span></h6>
          <h2>{data.refererswidthrawn} ETH</h2>
          </Reveal>
        </div>
      </div>        
    )}
    </>
    )}
    </div>
  )
};
export default Stats;