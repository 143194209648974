import  { useEffect, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { Link } from 'react-router-dom';
import useOnclickOutside from "react-cool-onclickoutside";
import TopAlert from "../modules/TopAlert";


setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);

const NavLink = props => (
  <Link 
    {...props}        
  />
);



const Header= function(props) {

    const [openMenu, setOpenMenu] = useState(false);
    const [openMenu1, setOpenMenu1] = useState(false);

    const [walletItem, setWalletItem]=useState("Connect Wallet");

    const handleBtnClick = () => {
      setOpenMenu(!openMenu);
    };
    const handleBtnClick1 = () => {
      setOpenMenu1(!openMenu1);
    };

    const closeMenu = () => {
      setOpenMenu(false);
    };
    const closeMenu1 = () => {
      setOpenMenu1(false);
    };

    const ref = useOnclickOutside(() => {
      closeMenu();
    });
    const ref1 = useOnclickOutside(() => {
      closeMenu1();
    });


    const [showmenu, btn_icon] = useState(false);
    useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
        btn_icon(false);
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
          totop.classList.add("show");
          
        } else {
          header.classList.remove("sticky");
          totop.classList.remove("show");
        } if (window.pageYOffset > sticky) {
          closeMenu();
        }
      });
      return () => {
        window.removeEventListener("scroll", scrollCallBack);
      };
    }, []);


    useEffect(() => {
      if(props.status===1) {
        setWalletItem("Wallet Connected");
      }
      else if(props.status===4) {
        setWalletItem("Connecting...");
      }
      else{
        setWalletItem("Connect Wallet");
      }

    },[props.status]);
    return (
    <header id="myHeader" className='navbar white'>
     <div className='container'>
       <div className='row w-100-nav'>
          <div className='logo px-0'>
              <div className='navbar-title navbar-item'>
                <NavLink to="/">
                <img
                    src="./img/logo.png"
                    className="img-fluid d-block"
                    alt="#"
                  />
                  <img
                    src="./img/logo-2.png"
                    className="img-fluid d-3"
                    alt="#"
                  />
                  <img
                    src="./img/logo-light.png"
                    className="img-fluid d-none"
                    alt="#"
                  />
                </NavLink>
              </div>
          </div>

                    
              <BreakpointProvider>
                <Breakpoint l down>
                  {showmenu && 
                  <div className='menu'>
                    <div className='navbar-item'>
                      <div ref={ref}>
                        <div className="dropdown-custom dropdown-toggle btn" 
                          onClick={handleBtnClick}
                          >
                          The Project
                        </div>
                        {openMenu && (
                          <div className='item-dropdown'>
                            <div className="dropdown" onClick={closeMenu}>
                              <NavLink to="/manifesto" onClick={() => btn_icon(!showmenu)}>Manifesto</NavLink>
                              <NavLink to="/milestones" onClick={() => btn_icon(!showmenu)}>Milestones</NavLink>
                              <NavLink to="/whitepaper" onClick={() => btn_icon(!showmenu)}>Whitepaper</NavLink>
                              <NavLink to="/faq" onClick={() => btn_icon(!showmenu)}>Frequently Asked Questions</NavLink>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='navbar-item'>
                      <div ref={ref1}>
                        <div className="dropdown-custom dropdown-toggle btn" 
                          onClick={handleBtnClick1}
                          >
                          Join
                        </div>
                        {openMenu1 && (
                          <div className='item-dropdown'>
                            <div className="dropdown" onClick={closeMenu1}>
                              <NavLink to="/asteroids" onClick={() => btn_icon(!showmenu)}>Claim Asteroid</NavLink>
                              <NavLink to="/random" onClick={() => btn_icon(!showmenu)}>Claim Random</NavLink>
                              <NavLink to=" " onClick={() =>  window.open("https://www.beltersday.com", "_self")}>Belters Day</NavLink>
                              <NavLink to="/kickstart" onClick={() => btn_icon(!showmenu)}>Kickstart</NavLink>
                              <NavLink to="/promote" onClick={() => btn_icon(!showmenu)}>Help Promote</NavLink>

                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/wallet" onClick={() => btn_icon(!showmenu)}>
                        My Claims <span className="badge bg-danger">{props.pending}</span>
                      </NavLink>
                    </div>
                  </div>
                  }
                </Breakpoint>

                <Breakpoint xl>
                  <div className='menu'>
                    <div className='navbar-item'>
                        <div ref={ref}>
                          <div className="dropdown-custom dropdown-toggle btn" 
                             onMouseEnter={handleBtnClick} onMouseLeave={closeMenu}>
                            The Project
                            <span className='lines'></span>
                            {openMenu && (
                            <div className='item-dropdown'>
                              <div className="dropdown" onClick={closeMenu}>
                                <NavLink to="/manifesto" onClick={() => btn_icon(!showmenu)}>Manifesto</NavLink>
                                <NavLink to="/milestones" onClick={() => btn_icon(!showmenu)}>Milestones</NavLink>
                                <NavLink to="/whitepaper" onClick={() => btn_icon(!showmenu)}>Whitepaper</NavLink>
                                <NavLink to="/faq" onClick={() => btn_icon(!showmenu)}>Frequently Asked Questions</NavLink>
                              </div>
                            </div>
                          )}
                          </div>
                          
                        </div>
                    </div>
                    <div className='navbar-item'>
                      <div ref={ref1}>
                          <div className="dropdown-custom dropdown-toggle btn" 
                             onMouseEnter={handleBtnClick1} onMouseLeave={closeMenu1}>
                            Join
                            <span className='lines'></span>
                            {openMenu1 && (
                            <div className='item-dropdown'>
                              <div className="dropdown" onClick={closeMenu1}>
                              <NavLink to="/asteroids" onClick={() => btn_icon(!showmenu)}>Claim Asteroid</NavLink>
                              <NavLink to="/random" onClick={() => btn_icon(!showmenu)}>Claim Random</NavLink>
                              <NavLink to=" " onClick={() =>  window.open("https://www.beltersday.com", "_self")}>Belter's Day</NavLink>
                              <NavLink to="/kickstart" onClick={() => btn_icon(!showmenu)}>Kickstart</NavLink>
                              <NavLink to="/promote" onClick={() => btn_icon(!showmenu)}>Help Promote</NavLink>
                              </div>
                            </div>
                          )}
                          </div>
                          
                        </div>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/wallet">
                      My Claims <span className="badge bg-danger">{props.pending}</span>
                      <span className='lines'></span>
                      </NavLink>
                    </div>
                  </div>
                </Breakpoint>
              </BreakpointProvider>

              <div className='mainside'>
                <button className="btn-main" onClick={() =>props.connect()}>{walletItem}</button>
              </div>
                  
      </div>

        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>
        <TopAlert show={props.alert.show} message={props.alert.message} variant={props.alert.variant} clear={props.clear} />               
      </div>     
    </header>
    );
}
export default Header;