    import axios from "axios";
    import moment from "moment";
    import { useEffect, useState } from 'react';
    import Render from "./Render";
    import Asteroids from "./Asteroids";
    import WaitModal from "./WaitModal";
    import NeedConnect from "./NeedConnect";
    import { APIURL } from '../config';

    function Asteroid(props) {        
        const expectedBlockTime = 1000; 
        const [asteroid, setAsteroid]=useState([]);
        const [status, setStatus]=useState(0);
        const [openMenu, setOpenMenu] = useState(true);
        const [openMenu1, setOpenMenu1] = useState(false);
        const [wait,setWait]=useState({"show":false});

        const handleBtnClick = () => {
            setOpenMenu(!openMenu);
            setOpenMenu1(false);
            document.getElementById("Mainbtn").classList.add("active");
            document.getElementById("Mainbtn1").classList.remove("active");
        };

        const handleBtnClick1 = () => {
            setOpenMenu1(!openMenu1);
            setOpenMenu(false);
            document.getElementById("Mainbtn1").classList.add("active");
            document.getElementById("Mainbtn").classList.remove("active");
        };
        
        const sleep = (milliseconds) => {
            return new Promise(resolve => setTimeout(resolve, milliseconds))
        }
    
        async function transferClaim() {
            try {
                props.alert('Please wait while check Gas Price', 'info', 6000);
                props.contract.methods.safeTransferFrom(props.account,document.getElementById('toaddr').value,props.id).estimateGas({ from: props.account}, function(error, estimatedGas) {
                    if(error!==null) {
                        let ErrMsg='';
                        try {
                            const pos=error.message.indexOf("{");
                            ErrMsg=error.message;
                            if(pos >0) ErrMsg=JSON.parse(error.message.substring(pos)).originalError.message;
                        }
                        catch(err) {
                            ErrMsg="An error occur";
                        };
                        props.alert(ErrMsg,'danger',6000);
                    }
                    else {
                        props.contract.methods.safeTransferFrom(props.account,document.getElementById('toaddr').value,props.id).send({from: props.account},async (error, transactonHash) => {
                            if(error!==null) {
                                let ErrMsg='';
                                try {
                                    const pos=error.message.indexOf("{");
                                    ErrMsg=error.message;
                                    if(pos >0) ErrMsg=JSON.parse(error.message.substring(pos)).originalError.message;
                                }
                                catch(err) {
                                    ErrMsg="An error occur";
                                };
                                props.alert(ErrMsg,'danger',6000);
                            }
                            else {
                                let transactionReceipt = null;
                                const message="Please wait until network confirm your transaction";
                                setWait(wait => ({"show":true, "title":"Please wait for Transaction Confirm","message": message}))
                                while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
                                    try {
                                        transactionReceipt = await props.web3.eth.getTransactionReceipt(transactonHash);
                                        await sleep(expectedBlockTime);
                                    }
                                    catch(error) {
                                        props.alert(error.message,'danger');
                                        
                                    }
                                    
                                }
                                setWait(wait => ({"show":false}));
                                if(transactionReceipt.status===true) {
                                    const message='Transaction Hash: '+transactionReceipt.transactionHash+' Gas used'+transactionReceipt.gasUsed.toString();
                                    props.alert(message,'success')
                                    setStatus(400)                                
                                }                            
                            }
                        });
                           
                            
                    }
    
                })
            }
            catch(error) {           ;
                console.log(error);
                props.alert(error.message,'danger');
                console.log(alert.show);
            }    
        }
        const propsId=props.id;
        const propsAlert=props.alert;
        useEffect(() => {
            axios.get("https://"+APIURL+"/data/asteroid?id="+propsId)
            .then(function (response) {
                setStatus(response.status);
                let day=moment(response.data.data.discovery.Discovery_date);
                response.data.data.discovery.Discovery_date=day.format('MMMM Do YYYY');                
                setAsteroid(response.data.data);
                
            })
            .catch(function(error) {
                console.log(error.response.status)
                propsAlert(error.response.data.message,'danger');
                setStatus(error.response.status)
            });
        },[propsId,propsAlert]);

        switch(status) {
            case 400:
                return (
                    <Asteroids />
                )
            case 200:   
                return (
                    <>
                    <WaitModal show={wait.show} title={wait.title} message={wait.message} />
                    <section className="jumbotron no-b l-vh pb-4" style={{backgroundImage: `url(${'./img/bg_claim.jpg'})`, backgroundRepeat: 'repeat', backgroundSize: 'contain',backgroundPosition: 'center top'}}>
                    <div className='container pb-4' style={ { backgroundColor: 'rgba(255,255,255)', opacity: 0.80}}>
                        <div className='row mt-md-5 pt-md-4' >
                            <div className="col-md-6 text-center p-2">
                                {asteroid.minted==="1" ? (
                                    <Render video={asteroid.video_ipfs} thumbnail={asteroid.thumbnail_ipfs} />                            
                                ): (
                                    <img className="img-fluid img-rounded mb-sm-30" alt="Asteroid Orbit from SSD" src={asteroid.orbit_img} />
                                )}
                                
                            </div>
                            <div className="col-md-6" >
                                <div className="item_info">
                                    <h2>({asteroid.principal}) {asteroid.name}</h2>  
                                    {asteroid.minted==="1" && (
                                        <>
                                        <h5>{asteroid.manifiest.description}</h5>
                                        <div className="spacer-10" style={{backgroundSize: 'cover'}}></div>
                                        </>
                                    )}           

                                    <span style={{fontSize:'18px'}}>Discovered by {asteroid.discovery.Discoverers}, at {asteroid.discovery.Discovery_site}, on {asteroid.discovery.Discovery_date}<br/>
                                    <strong>Reference: </strong> <a target="_new" href={'https://ssd.jpl.nasa.gov/tools/sbdb_lookup.html#/?sstr='+props.id}>Small Body Database - Jet Propulsion Laboratory (Nasa-CalTech) </a></span>
                                    <div className="spacer-30" style={{backgroundSize: 'cover'}}></div> 
                                    <div className="de_tab">
                                        <ul className="de_nav">
                                        {asteroid.minted==="1" && (
                                            <>
                                                <li id='Mainbtn' className="active"><span className="rounded-0" onClick={handleBtnClick}>Properties</span></li>
                                                <li id='Mainbtn1' className=''><span className="rounded-0" onClick={handleBtnClick1}>NFT Attributes</span></li>
                                            </>
                                            )}
                                        </ul>
                                        <div className="de_tab_content">
                                        {   openMenu1 && (
                                            <div className="tab-1 onStep fadeIn">
                                                <div className="row gx-2" style={{backgroundSize: 'cover'}}>
                                                    {asteroid.manifiest.attributes.map(attr =>(
                                                        <div key={attr.value} className="col-lg-4 col-md-6 col-sm-6" style={{backgroundSize: "cover"}}>
                                                            <div className="nft_attr">
                                                                {attr.trait_type ? 
                                                                (                                                                
                                                                    <h5>{attr.trait_type}</h5>
                                                                ): (
                                                                    <h5>Material detected</h5>
                                                                )}                                                            
                                                                <h4>{attr.value}</h4>
                                                            </div>
                                                        </div>
                                                    ))} 
                                                </div>                                                                           
                                            </div>
                                        )}
                                        {   openMenu && (
                                            <div className="tab-2 onStep fadeIn">
                                                <div className="spacer-10" style={{backgroundSize: 'cover'}}></div>
                                                <div className="item_info_counts">
                                                    <div className="item_info_type"><i className="fa fa-hashtag"></i>{asteroid.baseid}</div>
                                                    <div className="item_info_views"><i className="fa  fa-circle-o"></i>{asteroid.data.Orbit_type}</div>
                                                    <div className="item_info_like"><i className="fa  fa-usd"></i>{asteroid.price}</div>
                                                    <div className="item_info_type"><i className="fa fa-asterisk "></i>{asteroid.ranking}</div>
                                                </div>
                                                <div className="spacer-20" style={{backgroundSize: 'cover'}}></div>
                                                {asteroid.materials.length >0 && (
                                                <>
                                                    <h3 style={{marginBottom:'5px'}}>Materials</h3>
                                                    <div className="row gx-2" style={{backgroundSize: 'cover'}}>
                                                        {asteroid.materials.map( material => (
                                                            <div key={material} className="col-lg-4 col-md-6 col-sm-6" style={{backgroundSize: "cover"}}>
                                                                <div className="nft_attr">
                                                                    <h4>{material.toUpperCase()}</h4>
                                                                </div>
                                                            </div>
                                                        ))} 
                                                    </div>
                                                </>  
                                                )}                                                
                                              

                                            </div>
                                        )}
                                        </div> 

                                        <div className="spacer-20" style={{backgroundSize: 'cover'}}></div> 
                                        { props.transfer==="1" ? (
                                            <>
                                            { props.connected !==1 ? (
                                                <NeedConnect connected={props.connected} conectar={props.conectar} account={props.account}/>
                                                ):(
                                                    <>  
                                                    <div className="spacer-20" style={{backgroundSize: 'cover'}}></div>
                                                    <h3 style={{marginBottom:'5px'}}>Transfer</h3>
                                                    <form onSubmit={e => { e.preventDefault(); }}>
                                                        <div className="row">
                                                        <div className="col-md-8">
                                                            <label>To</label>  
                                                            <input className="form-control bg-black rounded-0" type="text" name="toaddr" id="toaddr" />   
                                                        </div>              
                                                        <div className="col-auto">
                                                            <label></label>    
                                                            <button className="btn-black fs-6 text rounded-0"  onClick={()=>{transferClaim()}}>Transfer</button>
                                                        </div>   
                                                        </div>
                                                    </form>                                                   
                                                    </>
                                                )}
                                            </>
                                        ):(
                                            <>                                            
                                            <h3 style={{marginBottom:'5px'}}>Claim Price</h3>  
                                            <div className="nft-item-price" style={{backgroundSize: 'cover',alignItems:'center', display: 'flex'}}>
                                                <img width="30" src="./img/misc/ethereum.svg" alt=""/>
                                                <span style={{marginLeft:'10px', fontWeight:'300px',fontSize:'30px'}}> {asteroid.claimprice} ETH</span>
                                            </div>                                                                           
                                            {asteroid.minted==="1" ? (
                                            <> 
                                                <div className="spacer-10" style={{backgroundSize: 'cover'}}></div>                                                       
                                                <h5>Claimed on block #{asteroid.mintedblock}, by {asteroid.mintedaddr}</h5>                                    
                                            </>
                                            ) : (
                                            <>
                                                <div className="spacer-10" style={{backgroundSize: 'cover'}}></div>
                                                <button className="btn-main fs-6 text rounded-0" onClick={()=>{props.add(asteroid.principal, asteroid.name,asteroid.claimprice,asteroid.id)}}>Add to cart</button>
                                            </>
                                            
                                            )}
                                            </>
                                        )}                          
                                    </div>                                    
     
                                </div>
                            </div>
                        </div>
                    </div>
                    </section>
                    </>
                );                
            default:
                return (
                    <></>
                )
        }                                          
    }
    export default Asteroid;