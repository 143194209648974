import axios from "axios";
import { useEffect, useState } from 'react';
import AsteroidList from "../modules/AsteroidsList";
import WaitModal from "../modules/WaitModal";
import NeedConnect from "../modules/NeedConnect";
import { APIURL } from '../config';
import SyncLoader from "react-spinners/SyncLoader";
import Launcher from "../components/Launcher";

function Random(props) {
    const[total, setTotal]=useState(0);
    const [wait,setWait]=useState({"show":false});
    const [asteroids, setAsteroids]=useState([]);
    const [ksBalance,setKsBalance]=useState(0);
    const [useKsBalance,setUseKsBalance]=useState(false);
    const [mintData,setMintData]=useState({});
    const [loading, setLoading] = useState(false);
    const expectedBlockTime = 1000;
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    const override= {
        position: 'absolute', /* or absolute */
        top: '10vh',
        left: '50%',
        'z-index': 1
      }

    function clearChange() {
        setTotal(0);
        setAsteroids([]);
    }

    function goToWallet(block) {
        window.location.assign('/recent?block='+block);
    }

    function getRandom() {
        setTotal(0);
        setLoading(true);
        const claim=document.getElementById('claim').value;
        const group=document.getElementById('group').value;
        let referer= localStorage.getItem('referer');       
        axios.post("https://"+APIURL+"/claim/random", {
            wallet: props.account,
            claim: claim,
            referer: referer,
            group: group,
        })
        .then(function(response) {
            console.log(response.data.data);
            let mintData=response.data.data;
            mintData.value=mintData.cost;
            setAsteroids(mintData.asteroids);
            setTotal(Number(props.web3.utils.fromWei(mintData.cost, 'ether')));            
            setMintData(mintData);
            setLoading(false)
        })
        .catch(function(error) {
            setLoading(false)
            props.alert(error.response.data.message,'danger',3000);
        });
    }
    async function mintRandom() {
        try {
            props.alert('Please wait while check Gas Price', 'info', 6000);
            console.log(useKsBalance);
            let value=mintData.value;
            if(useKsBalance===true) value=0;
            props.contract.methods.random(props.account,mintData.referer,mintData.amount,mintData.ids,mintData.cost,mintData.uniqid,mintData.expiration,mintData.msgHash,mintData.signature).estimateGas({ from: props.account, value: value}, function(error, estimatedGas) {
                if(error!==null) {
                    let errore = error.toString() 
                    if (errore.indexOf('Internal JSON-RPC error.') > -1) {
                          errore = errore.replace('\n', '').replace("Error: ", '').replace('Internal JSON-RPC error.', '')
                          error = JSON.parse(errore)
                    }
                    console.log(error);
                    props.alert(error.message,'danger',6000);
                }
                else {
                    try {
                        props.contract.methods.random(props.account,mintData.referer,mintData.amount,mintData.ids,mintData.cost,mintData.uniqid,mintData.expiration,mintData.msgHash,mintData.signature).send({from: props.account ,value: value},async (error, transactonHash) => {
                            if(error!==null) {
                                let errore = error.toString() 
                                if (errore.indexOf('Internal JSON-RPC error.') > -1) {
                                      errore = errore.replace('\n', '').replace("Error: ", '').replace('Internal JSON-RPC error.', '')
                                      error = JSON.parse(errore)
                                }
                                console.log(error);
                                props.alert(error.message,'danger',6000);
                            }
                            else {
                                let transactionReceipt = null;
                                const message="Please wait until network confirm your transaction";
                                setWait(wait => ({"show":true, "title":"Please Wait","message": message}))
                                while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
                                    try {
                                        transactionReceipt = await props.web3.eth.getTransactionReceipt(transactonHash);
                                        await sleep(expectedBlockTime);
                                    }
                                    catch(error) {
                                        props.alert(error.message,'danger',6000);
                                    }
                                    
                                }
                                setWait(wait => ({"show":false}));
                                if(transactionReceipt.status===true) {
                                    console.log(transactionReceipt);
                                    const message='Transaction Hash: '+transactionReceipt.transactionHash+' Gas used'+transactionReceipt.gasUsed.toString();
                                    props.alert(message,'success',3000);
                                    await sleep(3000);
                                    props.alert('Wait please','warning',5000);
                                    await sleep(5000);
                                    setAsteroids([]);
                                    setTotal(0);
                                    goToWallet(transactionReceipt.blockNumber);
                                }
                                else {
                                    props.alert("An error occur. Transaction reverted",'danger',6000);
                                }
                            }
                        });

                      }
                      catch(error) {
                        props.alert("Transaction Reverted");
                      }
                }
            })
        }
        catch(error) {           
            console.log(error)
            props.alert(error.message,'danger');
        }        

    }
    

    useEffect(() =>{
        if(props.connected===1) {
            props.contract.methods.kickStarters(props.account).call(function (err, res) {
                if (err) {
                    props.alert("An error occured","danger");            
                    return              
                }
                if(res>0) setUseKsBalance(true);
                else setUseKsBalance(false);
                setKsBalance(Number(props.web3.utils.fromWei(res, 'ether')));
              });
        }
        else {
            clearChange();
        }
    
    },[props,useKsBalance])    

    return (
        <>
            
            <WaitModal show={wait.show} title={wait.title} message={wait.message} />
            <section className="jumbotron no-bg l-vh pb-4" style={{backgroundImage: `url(${'./img/bg_join-claim.jpg'})`, backgroundRepeat: 'repeat', backgroundSize: 'contain', backgroundPosition: 'top'}}>
            
                <div className="container" >
                    <div className="row align-items-center">
                        <SyncLoader color={'#fff'} cssOverride={override} loading={loading} aria-label="Loading Spinner" data-testid="loader" />
                        <div className="col-md-12">
                            <div className="spacer-single"></div>
                            <div className="spacer-single"></div>
                            <div className="text-left mt-5">
                            <h1 className="fw-bold text-white mb-0 mt-4">Claim Asteroid Property Randomly</h1>
                            <p className="text-white lead fw-bold"><Launcher /></p>
                            </div>                
                        </div>
                    </div>
                    <div className="row justify-content-center" style={ {minHeight: '75vh'}}>
                        <div className="col-md-12 rounded pb-4 text-white">        
                        {props.connected !==1 ? (
                        <NeedConnect connected={props.connected} conectar={props.conectar} account={props.account}/>
                        ):(
                        <>  
                        <div className="spacer-10"></div>
                        <form className="row g-3 text-white" onSubmit={e => { e.preventDefault(); }}>
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-4">
                                <label className="text-white fw-bold text">Random Group</label>
                                <select onChange={() =>clearChange() } className="form-select bg-dark rounded-0 text-light" name="group" id="group">
                                    <option className="form-select bg-dark rounded-0 text-light" value="1">Group 1 (0.05 ETH each)</option>
                                    <option className="form-select bg-dark rounded-0 text-light" value="2">Group 2 (0.095 ETH each)</option>
                                    <option className="form-select bg-dark rounded-0 text-light" value="3">Group 3 (0.95 ETH each)</option>
                                    <option className="form-select bg-dark rounded-0 text-light" value="4">Group 4 (5 ETH each)</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <label className="text-white fw-bold text">Claim</label>
                                <select onChange={() =>clearChange() } name="claim" id="claim" className="form-select bg-dark rounded-0 text-light">
                                    <option className="form-select bg-dark rounded-0 text-light">1</option>
                                    <option className="form-select bg-dark rounded-0 text-light">2</option>
                                    <option className="form-select bg-dark rounded-0 text-light">3</option>
                                    <option className="form-select bg-dark rounded-0 text-light">4</option>
                                    <option className="form-select bg-dark rounded-0 text-light">5</option>
                                    <option className="form-select bg-dark rounded-0 text-light">6</option>
                                    <option className="form-select bg-dark rounded-0 text-light">7</option>
                                    <option className="form-select bg-dark rounded-0 text-light">8</option>
                                    <option className="form-select bg-dark rounded-0 text-light">9</option>
                                    <option className="form-select bg-dark rounded-0 text-light">10</option>
                                </select>
                            </div>
                            <div className="col-md-4 mt-4 pt-3">
                                <label></label>
                                <button className="btn-main fs-6 text rounded-0" onClick={() => getRandom()}>
                                    {total >0 ? (
                                        <>Refresh</>
                                    ): (
                                        <>Get list</>
                                    )}
                                </button>
                                { total >0 && (
                                    <button className="btn-main fs-6 text rounded-0"  onClick={() => mintRandom()}>Do claim</button>
                                )}
                            </div>
                            <div className="col-md-2"></div>
                            <div className="col-md-8 text-center fw-bold fs-5 text">
                            { (ksBalance >=total && total >0) && (
                                <>
                                <input className="form-check-input" type="checkbox" name="useKB" id="useKB" defaultChecked={useKsBalance} onChangeCapture={() => setUseKsBalance(!useKsBalance)} /> Use kickstart available balance ( {ksBalance} ETH )<br/>
                                </>
                                
                            )}
                            { (total > 0 && ksBalance < total) && (
                                    <>Your KickStart balance <strong>({ksBalance} ETH)</strong> isn't enough to pay this claim</>
                            )}                                
                                                             
                            </div>                        
                            
                        </form> 
                        <div className="row g-3">
                            <div className="col-md-12 text-center pb-4">
                            { total >0 && (
                                <>
                                <h3>Total {total} ETH</h3>
                                <div className="row justify-content-md-center">
                                {mintData.params.categories.map(param => (                                    
                                    <div className="col-md-3 text-center border-end border-gray btn-degrated">                                     
                                        <h6 className=""><span className="text-dark">ABC Category *{param.group}</span></h6>
                                        <h2>{param.cant}</h2>                                        
                                    </div>
                                ))}
                                </div>
                                </>

                            )}
                            </div>
                        </div>

                                

                </>

            )}             
            { asteroids.length === 0 ? (
                <>
                <div className="row g-3">
                    <div className="col-md-12 text-center pt-4">
                        <h2 className="text-white">Get your asteroids with great discounts</h2>
                        <p className="lead">You can claim a randomly selected asteroid at a heavily discounted price. According to the chosen group, the system generates a random list of 200 asteroids of different categories. From that list, at the time of claiming, the smart contract will select one or more, depending on the amount to claim (up to 10 simultaneous claims are allowed).</p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 rounded mb-4 p-2">
                        <div className="card shadow rounded">
                        <div className="ribbon ribbon-top-right "><span>38% off</span></div>
                            <img src="/img/logopromo.png" className="card-img-top" alt="Orbit for A802 FA" />
                            <div className="card-body border-top-0 rounded-bottom bg-light">
                                <h4 className="card-title card-random">Random Group 4</h4>
                                <p className="card-text">Average value: <strong>8.10 ETH</strong><br/>
                                <strong>List composition:</strong>
                                <ul>
                                    <li>Category 5: 2</li>
                                    <li>Category 4: 4</li>
                                    <li>Category 3: 58</li>
                                    <li>Category 2: 136</li>
                                </ul>
                                <span className="btn-view rounded-0 text-center" style={{borderTop: '1px solid #90A4AE', display:'block', width:'100%' }}>
                                <strong>Claim Price: </strong> 5.00 ETH /each
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 rounded mb-4 p-2">
                        <div className="card shadow rounded">
                        <div className="ribbon ribbon-top-right"><span>76% off</span></div>
                            <img src="/img/logopromo.png" className="card-img-top" alt="Orbit for A802 FA" />
                            <div className="card-body border-top-0 rounded-bottom bg-light">
                            <h4 className="card-title card-random">Random Group 3</h4>
                                <p className="card-text">Average value: <strong>4.05 ETH</strong><br/>
                                <strong>List composition:</strong>
                                <ul>
                                    <li>Category 4: 2</li>
                                    <li>Category 3: 4</li>
                                    <li>Category 2: 70</li>
                                    <li>Category 1: 100</li>
                                </ul>
                                <span className="btn-view rounded-0 text-center" style={{borderTop: '1px solid #90A4AE', display:'block', width:'100%' }}>
                                <strong>Claim Price: </strong> 0.95 ETH /each
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 rounded mb-4 p-2">
                        <div className="card shadow rounded">
                        <div className="ribbon ribbon-top-right"><span>89% off</span></div>
                            <img src="/img/logopromo.png" className="card-img-top" alt="Orbit for A802 FA" />
                            <div className="card-body border-top-0 rounded-bottom bg-light">
                            <h4 className="card-title card-random">Random Group 2</h4>
                                <p className="card-text">Average value: <strong>0.8675 ETH</strong><br/>
                                <strong>List composition:</strong>
                                <ul>
                                    <li>Category 3: 2</li>
                                    <li>Category 2: 4</li>
                                    <li>Category 1: 73</li>
                                    <li>Category 0: 121</li>
                                </ul>
                                <span className="btn-view rounded-0 text-center" style={{borderTop: '1px solid #90A4AE', display:'block', width:'100%' }}>
                                <strong>Claim Price: </strong> 0.0950 ETH /each
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 rounded mb-4 p-2">
                        <div className="card shadow rounded">
                        <div className="ribbon ribbon-top-right ribbon-danger"><span>92% off (*)</span></div>
                            <img src="/img/logopromo.png" className="card-img-top" alt="Orbit for A802 FA" />
                            <div className="card-body border-top-0 rounded-bottom bg-light">
                            <h4 className="card-title card-random">Random Group 1</h4>
                                <p className="card-text">Average value: <strong>0.61 ETH</strong><br/>
                                <strong>List composition:</strong>
                                <ul>
                                    <li>Category 2: 1</li>
                                    <li>Category 1: 45</li>
                                    <li>Category 0: 154</li>
                                    
                                </ul>
                                <br/>
                                <span className="btn-view rounded-0 text-center" style={{borderTop: '1px solid #90A4AE', display:'block', width:'100%' }}>
                                <strong>Claim Price: </strong> 0.05 ETH /each
                                </span>
                                </p>
                            </div>
                        </div>
                    </div> 
                    <div className="col-12 mb-4 p-2 text-center">
                        <small><strong>(*)</strong> Discounts are calculated based on the average value of the asteroids listed in each group.<br/>
                        Please read <a href="/faq"><strong>FAQ</strong></a> and the <a href="/whitepaper"><strong>Whitepaper</strong></a> for further information</small>
                    </div>                                                           
                </div>
                </>
            ):(
                <AsteroidList asteroids={asteroids} claimedlist="0" randomlist="1"/>
            )}               
                       
                            

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Random;