import Countdown, {zeroPad} from "react-countdown";

// Random component
const Completionist = () => <span></span>;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
    
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span>
        Asteroid claim begins next October 31, just in {days} days, {zeroPad(hours)} hours, {zeroPad(minutes)} minutes and {zeroPad(seconds)} seconds
      </span>
    );
  }
};

const Launcher = () => (
    <Countdown date={"2022-10-31T00:00:00"} renderer={renderer}/>  
);
export default Launcher;