import axios from "axios";
import { useEffect, useState } from 'react';
import AsteroidList from "./AsteroidsList";
import ProceedClaiming from "./ProceedClaiming";
import { APIURL } from '../config';

function Pending(props) {
    const [asteroids, setAsteroids]=useState([]);

    function getList(ids) {
        axios.get("https://"+APIURL+"/data/asteroids?pending=1&ids="+ids)
        .then(function (response) {
            setAsteroids(response.data.data);
        })
        .catch(function(error) {

        });

    };


    useEffect(() => {
        let listItems=[];
        props.items.map(item => (
            listItems.push(item.id)            
        ));
        if(listItems.length>0) getList(JSON.stringify(listItems));
    },[props.items]);

    useEffect(() => {
        props.refresh();
        // eslint-disable-next-line
    },[]);    

    return (
        <div style={ {minHeight: '66vh'}}>                        
        { props.items.length >0 && (
            <>
            <h2>Pending</h2>
            <AsteroidList asteroids={asteroids} pending="1" remove={props.remove}/>
            <ProceedClaiming account={props.account} connected={props.connected} items={props.items} clear={props.clear}  contract={props.contract} web3={props.web3} total={props.total} alert={props.alert}/>
            </>                 
        )}               
       
        </div>
    );
}
export default Pending;