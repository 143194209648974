export const faqabc=[
    {'nu':'1','ask':'What is the Asteroid Belt Club?','ans':'The Asteroid Belt Club is a long-term initiative focused on finding a solution to the problem of ownership of asteroids and their mineral resources and creating a community that contributes to their future exploration and exploitation using blockchain technology.'},
    {'nu':'2','ask':'How can I join the CLUB?','ans':'Quite simply, by being a holder of one of the NFTs we issue.'},
    {'nu':'3','ask':'Do I need to register to join the CLUB?','ans':'No. Just connect the wallet where you have the NFTs to our website, and that\'s it!'},
    {'nu':'4','ask':'Is DAO active yet?','ans':'No, not yet. The expected date is March 2025'},
    {'nu':'5','ask':'How is the club funded?','ans':'The club receives 70% of the revenue from the minting and further transfer fees and 50% of the revenue from the Asteroid Naming Service.'},
    {'nu':'6','ask':'Why did they choose Ethereum over other blockchains?','ans':'Ethereum is currently the blockchain that supports smart contracts most widely and by far the largest market capitalization, although there are many alternatives.'},
    {'nu':'7','ask':'What would happen if Ethereum has problems in the future?','ans':'Our contingency plan includes a mechanism to migrate the blockchain!'},
    {'nu':'8','ask':'Does owning an NFT give me ownership rights to Asteroid?','ans':'The Club mission is to work towards this in the future. All activities that the Club subsidizes, funds, or invests in require, in addition to club member approval, explicit recognition of members\' ownership rights to their respective asteroids.'},
    {'nu':'9','ask':'States really can not claim sovereignty over asteroids?','ans':'They can not, that\'s right. You can read the UN contract here: <a href="https://www.unoosa.org/pdf/gares/ARES_21_2222E.pdf" target="_blank" rel="noopener noreferrer">https://www.unoosa.org/pdf/gares/ARES_21_2222E.pdf</a>'},
    {'nu':'10','ask':'How much wealth is in the asteroid belt?','ans':'It is an estimated at $700 quintillion, according to NASA.'},
    {'nu':'11','ask':'And how much is 700 quintillion?','ans':'We are estimated to have over 50 trillion in total wealth on our planet, which is 14 million times the wealth of the Earth.'},
    {'nu':'12','ask':'Will it be possible to extract minerals from Asteroids?','ans':'Commercial extraction of precious metals from celestial bodies has been a developing industry for several years. Companies such as Moon Express, Transastra, Deep Space Industries (bought out by Bradford-Space), Planetary Resources (bought out by Consensys), and many others are currently developing various techniques for mining in space.'},
    ];
export const faqcoll=[
    {'nu':'13','ask':'What is the total number of Asteroids in the collection?','ans':'There are 605011. Any asteroids added to the database as of January 1, 2022.'},
    {'nu':'14','ask':'Does this list include the total number of Asteroids?','ans':'It includes all Asteroids listed and counted up to that date, but new asteroids continue to be discovered, slightly more than 1000 per year.'},
    {'nu':'15','ask':'Will new asteroids also have their NFT?','ans':'Of course, they will! We will update our collection quarterly to reflect all discoveries.'},
    {'nu':'16','ask':'Will I be able to mint one of the new asteroids?','ans':'Yes, the maximum number of mintings will always be 90% of the total number of asteroids when we add new asteroids, so a minting window will open until reaching the new maximum.'},
    {'nu':'17','ask':'How are NFTs classified?','ans':'We have classified the initial supply of asteroids into six categories, depending on their different properties. All new asteroids added in the updates will belong to a new categorization.'},
    {'nu':'18','ask':'How many NFTs are there in each category?','ans':'0 - 473211<br/>1 - 127865<br/>2 - 2671<br/>3 - 1154<br/>4 - 72<br/>5 - 38'},
    {'nu':'19','ask':'On what basis did you determine the categories?','ans':'We considered the following factors: estimated values, type of orbit, diameter, spectral analysis, and their approach to Earth or Mars by the year 2150.'},
    {'nu':'20','ask':'How much does it cost to claim an asteroid directly?','ans':'Prices depend on the category:<br/>0 - 0.5 ETH<br/>1 - 1 ETH<br/>2 - 5 ETH<br/>3 - 10 ETH<br/>4 - 40 ETH<br/>5 - 100 ETH<br/>Over time prices may be adjusted, but they are always upward, never downward.'},
    {'nu':'21','ask':'Do these prices include the Gas Fee?','ans':'No. As you are the one calling the Smart Contract, you must have enough ETH in your wallet to cover the Gas Fee. You can check the current cost of Gas at <a href="https://etherscan.io/gastracker" target="_blank" rel="noopener noreferrer">Etherscan</a>'},
    {'nu':'22','ask':'Can I claim more than one Asteroid per transaction?','ans':'Yes, the website allows you to claim up to 10 asteroids per transaction.'},
    {'nu':'23','ask':'How did you create the Asteroid list?','ans':'We used the official database of the <a href="https://minorplanetcenter.net/" target="_blank" rel="noopener noreferrer">MPC Database of the Minor Planet Center of the International Astronomical Union</a>. We extracted a lot of data from <a href="https://ssd.jpl.nasa.gov/" target="_blank" rel="noopener noreferrer">NASA Solar System Dynamics</a> and <a href="https://www.asterank.com" target="_blank" rel="noopener noreferrer">Asterank</a>.'},
    {'nu':'24','ask':'Do all NFTs have the same voting rights?','ans':'No. Voting rights depend on their category. That is one vote per 0.5 ETH of the original nominal cost. Category 0 - 1 vote. Category 1 - 2 votes. Category 2 - 10 votes. Category 3 - 20 votes. Category 4 - 80 votes. Category 5 - 200 votes.'},
    ];
export const faqrand=[
    {'nu':'25','ask':'What is random claiming?','ans':'As the name suggests, it is a way of leaving the Asteroids to be claimed to chance.'},
    {'nu':'26','ask':'Why did you introduce random claiming?','ans':'To encourage acceptance of the project with a lower initial investment, we offer significant discounts if you are willing to let chance decide your asteroid!'},
    {'nu':'27','ask':'Will the random claim always be available?','ans':'It will be available at the initial stage of the project and until ABC Igniter or the DAO at a later date deems it appropriate.'},
    {'nu':'28','ask':'How is the list composed?','ans':'The list will always be composed of 200 random Asteroids. It can be refreshed as many times as desired and will be valid for 120 seconds.'},
    {'nu':'29','ask':'How does one group differ from another?','ans':'They differ in the proportion of asteroids of each category that make it up.'},
    {'nu':'30','ask':'What does group 1 include?','ans':'154 Category 0 Asteroids (77%), 45 Category 1 Asteroids (22.5%), and 1 Category 2 Asteroid (0.5%). Giving an average value per Asteroid of 0.61 ETH'},
    {'nu':'31','ask':'What does group 2 include?','ans':'121 Category 0 Asteroids (60.5%), 73 Category 1 Asteroids (36.5%), 4 Category 2 Asteroids (2%), and 2 Category 3 Asteroids (1%). Giving an average value per Asteroid of 0.8675 ETH'},
    {'nu':'32','ask':'What does group 3 include?','ans':'100 Category 1 Asteroids (50%), 70 Category 2 Asteroids (35%), 28 Category 3 Asteroids (14%), and 2 Category 4 Asteroids (1%). Giving an average value per Asteroid of 4.05 ETH'},
    {'nu':'33','ask':'What does group 4 include?','ans':'136 Category 2 Asteroids (68%), 58 Category 3 Asteroids (29%), 4 Category 4 Asteroids (2%), and 2 Category 5 Asteroids (1%). Giving an average value per Asteroid of 8.1 ETH'},
    {'nu':'34','ask':'Is it sure that the list contains these quantities?','ans':'Yes, in case of running out of Asteroids of any Category, the corresponding groups will be automatically disabled.'},
    {'nu':'35','ask':'How is the average value in a group calculated?','ans':'We calculate it by adding the direct claim value of each asteroid according to its category and dividing by 200.'},
    {'nu':'36','ask':'Will the groups always be the same?','ans':'ABCIgniter, and eventually DAO will be able to change both the composition of the groups and their mint price.'},
];
export const faqkick=[
    {'nu':'37','ask':'What is the Kickstarter program?','ans':'It is a program that rewards the enthusiasm of early adopters of the Asteroid Belt Club proposal by multiplying the amount of ETH they bring in according to a sliding scale of rewards dependent on amounts previously raised.'},
    {'nu':'38','ask':'By what amount will my deposit be multiplied?','ans':'The first 50 ETH collected in the program will be multiplied by 8, the next 50 ETH by 4, the next 100 ETH by 3, and the next 300 by 2.'},
    {'nu':'39','ask':'Is there a minimum deposit?','ans':'Yes, it is 0.05 ETH'},
    {'nu':'40','ask':'Is there a maximum deposit?','ans':'Yes, the maximum is what is left to complete the current multiplier. For example, the first maximum amount is 50 ETH; if you have deposited 30 ETH, the maximum at that time would be 20 ETH.'},
    {'nu':'41','ask':'When does the program end?','ans':'The program will ﬁnalize when 500 ETH has been collected, which will convert to 1500 ETH of buying power. But we will continue to allow deposits without a multiplier, to top up small balances and not leave them unused.'},
    {'nu':'42','ask':'How do I know how much my deposit will be multiplied by now?','ans':'You can see the current multiplier and the remaining amount of the program at <a href="/kickstart">Kickstart section</a>'},
    {'nu':'43','ask':'What happens to my ETH when I deposit it?','ans':'They will be deposited in your account at the current multiplier, and you can deduct them in future property claims or even transfer them in whole or in part to a third party.'},
    {'nu':'44','ask':'Will my balance expire?','ans':'No, your balance will remain until you want to use it. Remember, however, that there is a limit to how many asteroids you can mint. If the limit reaches and you still have a balance, you will have to wait until the next update.'},
];
export const faqstrat=[    
    {'nu':'45','ask':'What is the purpose of the strategic reserve?','ans':'The purpose of the reserve is to allow CLUB to enter into agreements with governments, institutions, and corporations that will result from the recognition of ownership claims to these asteroids.'},
    {'nu':'46','ask':'How much is the Strategic Reserve?','ans':'The maximum of the strategic reserve will always be 10% of the total asteroids.'},
    {'nu':'47','ask':'Who decides what agreements to make?','ans':'Each agreement must be proposed and approved according to the rules set out in the DAO Smart Contract.'},
    {'nu':'48','ask':'How is it decided which asteroids go into the strategic reserve?','ans':'The market. Anyone can mint any asteroid. The rules are simple to avoid possible abuses. Asteroids minted with agreement purchasing power must never exceed 10% of the total, and until 500000 asteroids are minted, the total contract purchasing power spent must never exceed 10% of the collected ETH.'},
    {'nu':'49','ask':'Will the Strategic Reserve Asteroids have full rights?','ans':'NO. Initially, NFTs representing such claims WILL NOT have membership rights in the CLUB. Under the rules of the agreement, holders of NFTs issued under a strategic agreement will be able to acquire full membership by paying a fee.'},
    {'nu':'50','ask':'What will be the rate to be paid for these asteroids?','ans':'The cost of citizenship for asteroids minted under an agreement will be made explicit in each specific settlement, but must always be greater than or equal to the minimum price of citizenship in effect in the contract at the time the agreement is approved.'},
    {'nu':'51','ask':'What is the minimum cost of citizenship?','ans':'The initial cost of citizenship is 0.1 ETH for each vote the asteroid has (their voting power depends on their category). This price can and will be changed later depending on the market price, but it must always be higher than the previous price.'},
    {'nu':'52','ask':'Are there any agreements under discussion?','ans':'No strategic agreements at first planned.'},
 ];
export const faqbelt=[
    {'nu':'53','ask':'What is Belter\'s Day?','ans':'We want every project enthusiast to have the opportunity to claim one of the available asteroids to them, and for free. To that end, we have created Belters Day, an event where we celebrate the birth of our community by giving away tickets to make this possible. In the <strong><a href="https://www.beltersday.com/faq">FAQ on their website</a></strong> can be found all the answers.'},
];
export const faqans=[
    {'nu':'54','ask':'What is the Asteroid Naming Service?','ans':'It is a service that allows the claim holder to name those asteroids that have not been named in time by their discoverer according to current IAU standards.'},
    {'nu':'55','ask':'How can the name of an NFT be changed if they are unchangeable?','ans':'We will record the new name in the club database, and a new version of the NFT will be issued that will reﬂect the change.'},
    {'nu':'56','ask':'I have a claim on an already-named NFT; can I rename it?','ans':'We will not admit to renaming asteroids.'},
    {'nu':'57','ask':'When will the service be available?','ans':'It will be available in the second half of 2023.'},
    {'nu':'58','ask':'How much will the service cost?','ans':'Still to be defined.'},
    {'nu':'59','ask':'Where will the money collect go?','ans':'ANS will charge a small fee for the service. We will divide the remaining income into two halves. We will offer one-half to the IAU to support their activities on the condition that they acknowledge your name on their documents and in the Minor Planet Center database. The other half will go to the club treasury.'},
 ];
export const faqign=[
    {'nu':'60','ask':'What is the Asteroid Belt Club Igniter?','ans':'It is the team that conceived, designed, developed, and launched this project.'},
    {'nu':'61','ask':'What is the mission of ABC Igniter?','ans':'Its mission is to organize the launch of CLUB, develop and implement the technology that will make it a decentralized autonomous organization, guard the assets of CLUB until DAO can manage them, develop and implement the Asteroid Naming Service, and propose new projects for the members of CLUB.'},
    {'nu':'62','ask':'The ABC Igniter will receive something at the beginning?','ans':'Upon release of the CLUB smart contract, we will randomly claim ten asteroids and reserve a purchasing power of 1000 ETH to allocate to current and future contributors.'},
    {'nu':'63','ask':'How will ABC Igniter be funded?','ans':'The team will receive 30% of the initial claim revenue, royalties from subsequent transfers, and membership upgrade revenue from claims resulting from agreements.'},
];