import axios from "axios";
import { useEffect, useState } from 'react';
import AsteroidList from "./AsteroidsList";
import Search from "./Search";
import { APIURL } from '../config';

function Collection(props) {
    const [asteroids, setAsteroids]=useState([]);
    const [paginator, setPaginator]=useState([0,1]);


    function getList(search,length,category,page) {
        let block='';
        if(props.useblock !== undefined) {
            if(props.block !== undefined) {
                block="&block="+props.block;
            }
            else {
                block="&block=0";
            }            
        }
        if(props.block !== undefined) {
            block="&block="+props.block;
        }
        if(props.connected===1) {
            //getBeltersData(props.account);
            props.contract.methods.walletOfOwner(props.account).call(function (err, res) {
                if (err) {
                    props.Alert(err,'danger',4000)
                    console.log("An error occured", err)              
                    return              
                }        
                const ids=JSON.stringify(res)
                axios.get("https://"+APIURL+"/data/asteroids?search="+search+"&length="+length+"&category="+category+"&page="+page+"&minted=1&ids="+ids+block)
                .then(function (response) {
                    console.log(response.data);
                    setAsteroids(response.data.data);
                    let prev=0;
                    let next=2;
                    if(page >1) {
                      prev=page-1;
                      next=page+1;
                    }
                    setPaginator([prev,next]);
                })
                .catch(function(error) {
                    console.log(error)
                    props.Alert(error.response.status,'danger',4000)
                });
            });
        }
    };

    

    useEffect(() => {
        if(props.connected===1) {
            getList('',20,'',1);                            

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.connected, props.account]);
    return (
        <div style={ {minHeight: '66vh'}}>
            <Search search={getList} pages={paginator} />
            <AsteroidList asteroids={asteroids} claimedlist="1"/>            
        </div>
    );
}
export default Collection;