import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
function Render(props) {
    return (
        <div>
        <Video
            controls={['PlayPause', 'Seek', 'Time', 'Volume']}
            poster={props.thumbnail}
            onCanPlayThrough={() => {
                // Do stuff
            }}
            onLoadedData={() => { }}>
            <source src={props.video} type="video/mp4" />           
        </Video>
        </div>
    );
}
export default Render;