import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import ReactPlayer from "react-player";
import Launcher from './Launcher';
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const slidermain= () => (
 <div className="container">
  
    <div className="row align-items-start">
          <div className="col-md-6" style={ {minHeight: '50vh'}}>
              <div className="spacer-60"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
              <h2 className=""><span className="text-muted fs-1 text">Take humanity beyond Earth</span></h2>
              </Reveal>
              <Reveal className='onStep text-light' keyframes={fadeInUp} delay={900} duration={800} triggerOnce>
              <p className=" lead">
              In the coming decades, humanity will become a multi-planetary species. We will live on Mars, have a permanent presence on the Moon, and harness the water and mineral resources of the asteroids to accomplish this.
              </p>
              <p className="lead">
                The <strong>Asteroid Belt Club</strong> is a long-term initiative focused on solving the problem of asteroid property rights, which are essential for the rational and peaceful exploitation of asteroid mineral resources.
              </p>
              <p className="lead">
                Anyone can become a member. Only those who own at least one property claim to one of the more than 600 thousand asteroids uniquely identified and numbered by the IAU and represented by a non-fungible token can become members.              
              </p>
              <p className="lead">
              <strong>Initially, only 550,000 full members will be accepted</strong>. 
              </p>
              <p className="lead">
              Each token expresses a property claim to the asteroid it represents. Its possession will enable participation in an autonomous management community whose rules will ensure that it can accumulate resources, fund ventures, and promote its legitimacy as a property registry.
              </p>
              </Reveal>

          </div>
          <div className="col-md-6" style={{fontSize:"20px"}}>
          <div className="spacer-60"></div>
          <div className="spacer-60"></div>
            <div className="embeb-responsive embeb-responsive-21by9">
              <video src="/video/manifesto.mp4" style={{width: '100%', height: '100%'}} preload="auto" controls></video>
            </div>
          </div>
          <div className="col-md-12 text-center">
            <Reveal className='onStep' keyframes={fadeInUp} delay={1600} duration={900} triggerOnce>
              <h4 className='fw-bold text-white'>Join now and be one of the first members of the initiative that will forever transform the notion of ownership of space resources!</h4>
            </Reveal>            
          </div>
          
      </div>
      <div style={{marginBottom:"10vh"}}></div>
    </div>
);
export default slidermain;