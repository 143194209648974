import Stats from "../components/Stats";


function Milestones(props) {
    return (
        <section className="jumbotron no-bg l-vh pb-4" style={{backgroundImage: `url(${'./img/bg_milestone.jpg'})`, backgroundRepeat: 'no-repeat'}}>
        <div className="container"  >
            <div className="row align-items-center">
            <div className="col-md-8" >
                    <div className="text-left mt-5">
                        <h1 className="fw-bold text-white mb-0 mt-4">Milestones</h1>
                        <h4 className="fw-bold text-white-50">The steps planned for the launch and start-up of the Asteroid Belt Club
are ruled according to the following schedule:</h4>
                    </div>                
            <div className="rounded mt-4 mb-5">
            <div className="container fs-6 text text-white fw-bold">
                <div className="row bg-dark py-1">
                <div className="col-11">May 2021 - Initial project design.</div>
                <div className="col-1"><i className="fa fa-check icongreen"></i></div>
                </div>
                <div className="row bg-dark bg-opacity-50 py-1">
                    <div className="col-11">October - December 2021 - Data collection and database development.</div>
                    <div className="col-1"><i className="fa fa-check icongreen"></i></div>
                </div>
                <div className="row bg-dark py-1">
                    <div className="col-11">January - February 2022 - Design of the NFT.</div>
                    <div className="col-1"><i className="fa fa-check icongreen"></i></div>
                </div>
                <div className="row bg-dark bg-opacity-50 py-1">
                    <div className="col-11">February - April 2022 - Elaboration of the smart contract.</div>
                    <div className="col-1"><i className="fa fa-check icongreen"></i></div>
                </div>
                <div className="row bg-dark py-1">
                    <div className="col-11">April - July 2022 - Website development.</div>
                    <div className="col-1"><i className="fa fa-check icongreen"></i></div>
                </div>
                <div className="row bg-dark bg-opacity-50 py-1">
                    <div className="col-11">September - October 2022 - CLUB launch: publication of the smart contract, activation of websites.</div>
                    <div className="col-1"><i className="fa fa-check icongreen"></i></div>
                </div>

                <div className="row bg-dark bg-opacity-50 py-1">
                    <div className="col-11">March 1, 2023 - First database update and preparation of additional NFTs. Later quarterly basis.</div>
                    <div className="col-1"><i className="fa fa-circle-o iconred"></i></div>
                </div>
                <div className="row bg-dark py-1">
                    <div className="col-11">July 7, 2023 - First Belter's Day.</div>
                    <div className="col-1"><i className="fa fa-circle-o iconyellow"></i></div>
                </div>                
                <div className="row bg-dark py-1">
                    <div className="col-11">September 2023 - Launch of the ANS.</div>
                    <div className="col-1"><i className="fa fa-circle-o iconred"></i></div>
                </div>
                <div className="row bg-dark bg-opacity-50 py-1">
                    <div className="col-11">August - December 2024 - Development of the DAO.</div>
                    <div className="col-1"><i className="fa fa-circle-o iconred"></i></div>
                </div>
                <div className="row bg-dark py-1">
                    <div className="col-11">March 2025 - Launch of the DAO.</div>
                    <div className="col-1"><i className="fa fa-circle-o iconred"></i></div>
                </div>
                <div className="row bg-dark bg-opacity-50 py-1">
                    <div className="col-11">August 2025 - Transfer of Vault and Agreement authority to the DAO.</div>
                    <div className="col-1"><i className="fa fa-circle-o iconred"></i></div>
                </div>
            </div>
            </div>
            <div className="col-md-4 xs-hide"></div>
        </div>
        </div></div>
        <Stats main="1" web3={props.web3}/>
    </section>
      
    );
}
export default Milestones;