import React from 'react';
import { CONTRACT_ADDRESS, VAULT_ADDRESS, PAYMENT_ADDRESS, NOTARY_ADDRESS, EXPLORER } from '../config';

const footer= () => (
  <footer className="footer-light">
            <div className="container">
                <div className="row">
                    <div className="col-md-2 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Marketplace</h5>
                            <ul>
                                <li><a href="/claimed">Asteroids claimed</a></li>
                                <li><a href="https://opensea.io/collection/asteroid-belt-club" target="_blank" rel="noopener noreferrer">Opensea</a></li>
                                <li><a href="https://x2y2.io/collection/asteroid-belt-club" target="_blank" rel="noopener noreferrer">X2Y2</a></li>
                                <li><a href="https://rarible.com/astbeltclub" target="_blank" rel="noopener noreferrer">Rarible</a></li>
                                <li><a href="https://looksrare.org/es/collections/0x89Ff915e6035E6D9DfcAC41E921EEBCa9423C78E" target="_blank" rel="noopener noreferrer">LooksRare</a></li>                                
                                <li><a href="https://mintable.app/" target="_blank" rel="noopener noreferrer">Mintable</a></li> 
                                <li><a href="https://raritysniper.com/nft-drops-calendar">NFT Drops</a></li>
                                <li><a href="https://nftcalendar.io/"><img src="/img/nftcalendario.svg" width="50px;"/></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Research</h5>
                            <ul>
                                <li><a href="https://minorplanetcenter.net/" target="_blank" rel="noopener noreferrer">Minor Planet Center</a></li>
                                <li><a href="https://ssd.jpl.nasa.gov/" target="_blank" rel="noopener noreferrer">SSD Jet Propulsion Lab</a></li>
                                <li><a href="https://www.asterank.com" target="_blank" rel="noopener noreferrer">Asterank</a></li>
                                <li><a href="https://nssdc.gsfc.nasa.gov/planetary/planets/asteroidpage.html" target="_blank" rel="noopener noreferrer">NASA Solar System</a></li>
                                <li><a href="https://cneos.jpl.nasa.gov/" target="_blank" rel="noopener noreferrer">Nasa CNEOS</a></li>
                                <li><a href="https://en.wikipedia.org/wiki/Asteroid_mining" target="_blank" rel="noopener noreferrer">Asteroid Mining Wikipedia</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Community</h5>
                            <ul>
                            <li><a href="https://medium.com/@asteroidbeltclub" target="_blank" rel="noopener noreferrer">Medium Blog</a></li>
                                <li><a href="https://discord.gg/pVErrfQZTc" target="_blank" rel="noopener noreferrer">Discord Server</a></li>
                                <li><a href="https://t.me/asteroidbeltclub" target="_blank" rel="noopener noreferrer">Telegram Channel</a></li>
                                <li><a href="https://www.reddit.com/r/asteroidbeltclub" target="_blank" rel="noopener noreferrer">Sub Reddit</a></li> 
                                <li><a href="https://www.beltersday.com/" target="_blank" rel="noopener noreferrer">Belter´s Day</a></li>
                                <li><a href="https://www.abcigniter.com/affiliates" target="_blank" rel="noopener noreferrer">Affiliates</a></li>                                
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-5 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>BlockChain</h5>
                            <ul>
                                <li><a href={EXPLORER+CONTRACT_ADDRESS} target="_blank" rel="noopener noreferrer"><strong>Smart Contract: </strong>{CONTRACT_ADDRESS}</a></li>
                                <li><a href={EXPLORER+PAYMENT_ADDRESS} target="_blank" rel="noopener noreferrer"><strong>Splitter: </strong>{PAYMENT_ADDRESS}</a></li>
                                <li><a href={EXPLORER+VAULT_ADDRESS} target="_blank" rel="noopener noreferrer"><strong>Vault: </strong>{VAULT_ADDRESS}</a></li>
                                <li><a href={EXPLORER+NOTARY_ADDRESS} target="_blank" rel="noopener noreferrer"><strong>Notary: </strong>{NOTARY_ADDRESS}</a></li>
                                <li>&nbsp;</li>
                                <li><a href="https://gateway.pinata.cloud/ipfs/QmZrStLoXn4r2Dw1r7qV3acsqjmAxvZpcHSHG1C9PXTnJq" target="_blank" rel="noopener noreferrer"><strong>Current complete IPFS asteroid list (in csv, notarized)</strong></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="subfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="de-flex">
                                <div className="de-flex-col">
                                    <span style={{'cursor':'pointer'}}onClick={()=> window.open("https://www.abcigniter.com", "_self")} className="copy">&copy; Copyright 2022 - <strong>ABCIgniter</strong></span>
                                </div>
                                <div className="de-flex-col">
                                    <div className="social-icons">
                                        <span onClick={()=> window.open("https://facebook.com/AsteroidBeltClub","_blank")}><i className="fa fa-facebook fa-lg"></i></span>
                                        <span onClick={()=> window.open("https://twitter.com/AstBeltClub", "_blank")}><i className="fa fa-twitter fa-lg"></i></span>
                                        <span onClick={()=> window.open("https://www.youtube.com/channel/UCJT9Hpe3nzYqrG9vyBXUEHA", "_blank")}><i className="fa fa-youtube fa-lg"></i></span>
                                        <span onClick={()=> window.open("https://www.instagram.com/AsteroidBeltClub/", "_blank")}><i className="fa fa-instagram fa-lg"></i></span>
                                        <span onClick={()=> window.open("https://www.pinterest.com/AsteroidBeltClub", "_blank")}><i className="fa fa-pinterest fa-lg"></i></span>
                                        <span onClick={()=> window.open("https://www.github.com/", "_blank")}><i className="fa fa-github-alt fa-lg"></i></span>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
);
export default footer;