import Stats from "../components/Stats";


function Whitepaper(props) {
    return (
        <section className="jumbotron no-bg l-vh pb-4" style={{ backgroundImage: `url(${'./img/bg_whitepaper.jpg'})`, backgroundRepeat: 'no-repeat' }}>
            <div className="container" >
                <div className="row align-items-center">
                    <div className="col-md-8">
                        <div className="text-left mt-5">
                            <h1 className="fw-bold text-black mb-0 mt-4">Whitepaper</h1>
                            <h4 className="fw-bold text-black-50">“The first trillionaire there will ever be is the person who mines asteroids”<br /><small>(Neil deGrasse Tyson - Astrophysic)</small></h4>
                            <p className="p2">PDF Notarized version <a href="https://nft.asteroidbeltclub.com/ipfs/QmWHn7okNi9wq2t1XR2yc29XbYLXY8hbbABivgPnK9pKqh" target="_new">Download Here</a></p>
                        </div>
                        <div className="rounded p-4 bg-black bg-opacity-50 overflow-scroll mb-5 mt-4 text-light" style={{ minHeight: '66vh' }}>
                            <p className="p-2 fw-bold">Introduction:</p>
                            <p className="p-2">Humanity is taking its first steps toward becoming a multi-planetary species.</p>
                            <p className="p-2">The space race, fueled during the Cold War by the conflict between the Eastern and Western blocs, is now back on track thanks to the private sector. A permanent presence on the moon and the colonization of Mars are on the agenda and will become a reality in the coming decades.</p>
                            <p className="p-2">New technologies are cutting costs, simplifying processes, and creating new industries: from tourism to the exploitation of mineral resources in space are or will be possible in the future. Companies like SpaceX, Blue Origin, and Virgin Galactic are a few contenders in this new race for space, animated by all sorts of ongoing initiatives.</p>
                            <p className="p-2">One of these future industries will be mining the asteroid belt, and the reason is simple: according to NASA, gold, iron, nickel, water, and other valuable resources can be found there - especially rare metals that are widely used in electronics - the total value of minerals found in the asteroid belt is estimated at $700 quintillion (that's a 7 followed by 20 zeros)</p>
                            <p className="p-2">The concentration of minerals in asteroids is much higher than on Earth, their extraction is potentially very profitable and would have an immensely positive impact on Earth's ecosystems.</p>
                            <p className="p-2">In the future, infrastructure projects in space (e.g., space stations and habitats on Mars, Ganymede, and Ceres) will require mining the minerals of asteroids as well as their large water deposits, both for fuel production and for vital support of explorers and pioneers.</p>
                            <p className="p-2"><b>Extracting minerals from the asteroid belt will present enormous technical and economic challenges, as well as challenges regarding property rights. Who owns the asteroids? </b></p>
                            <p className="p-2">Each country recognized by the UN has undertaken not to lay claim to a celestial body in Article II of the "Treaty on Principles Governing the Activities of States in the Exploration and Use of Outer Space, Including the Moon and Other Celestial Bodies.” (<a href="https://www.unoosa.org/pdf/gares/ARES_21_2222E.pdf" target="_blank" rel="noreferrer">https://www.unoosa.org/pdf/gares/ARES_21_2222E.pdf</a>), That means that their legal systems are unable to grant, recognize, or protect property rights, which is a serious problem and prevents the peaceful use of the natural resources of outer space.</p>
                            <p className="p-2">Property rights give us certainty in the exploration and exploitation of resources, and this in turn facilitates the involvement of venture capital for the development of an industry that requires large amounts of highly speculative capital for an activity whose economic returns will not be visible for several decades, and that requires technologies that are still in the early stages of development, an entrepreneurial and adventurous spirit, and resources far beyond what many countries and agencies are currently investing. </p>
                            <p className="p-2">This problem could be solved thanks to blockchain technology, and with this initiative, we propose a mechanism to resolve future disputes while encouraging more entrepreneurs and facilitating their participation in the development of technologies that will make the adventure of living in space possible soon.</p>
                            <p className="p-2">When the Soviet Union sent Sputnik into orbit, the expense was so great that only the world's great powers, often in conjunction with huge public-private consortia, could even think of putting a satellite into orbit. Today, this is entirely feasible for small companies and friends associations. Falling transportation costs and technological acceleration in the computer industry have made this possible. All this has happened in less than 70 years.</p>
                            <p className="p-2">Living in space and using the natural resources of the Moon, Mars, and asteroids seem more science fiction than reality, but they could become part of our lives much sooner than we think. We should only follow the same spirit that led Homo Sapiens to leave the African savannah and conquer the planet, and that enabled us to face a million impossible tasks and find a million brilliant solutions to them. <b>It is now time to face this problem.</b></p>
                            <p className="p-2 fw-bold">Asteroid Belt Club</p>
                            <p className="p-2">The <b>Asteroid Belt Club</b> is a long-term initiative focused on providing a solution to the property rights problem asteroids and their mineral resources have, generating a community that, making use of Blockchain Technology, will contribute to their future exploration and exploitation.</p>
                            <p className="p-2">This community will be a DAO (Decentralized Autonomous Organization). Membership will require the possession of an NFT minted by the Asteroid Belt Club ( hereafter referred to simply as NFT), each representing claims to the property rights of various asteroids listed and numbered in the MPC database of the <b>Minor Planet Center</b>, officially recognized by the <b>International Astronomical Union</b>. The Asteroid Belt Club will take care of several activities, which include the following:
                            <ul>
                                    <li>A - Act as a registry for claims of ownership of asteroids.</li>
                                    <li>B - Promote and support activities related to the effective recognition of the property rights of its members.</li>
                                    <li>C - Develop mechanisms to enable peaceful resolution of conflicts related to rights to use and explore asteroids.</li>
                                    <li>D - Support asteroid exploration, disclosure, reconnaissance, and exploitation.</li>
                                    <li>E - Risk investment in the exploration and exploitation of resources in space.</li>
                                </ul>
                            </p>
                            <p className="p-2">To achieve its objectives, the Asteroid Belt Club will have a set of resources at its disposal, the magnitude of which will depend on the project's success.</p>
                            <p className="p-2"><b>A.	Claims to Property Rights.</b></p>
                            <p className="p-2">The blockchain allows us to register property rights (e.g., digital and intellectual property) immutably and independently of other organizations, and to make claims to property. With NFTs, we can do this and easily transfer these claims to third parties. We have established a registry to make property claims on asteroids that are currently numbered and cataloged by the IAU, and we have created a mechanism that will allow us to periodically make claims on asteroids that are discovered in the future.</p>
                            <p className="p-2">Each asteroid claimed will be represented by an NFT that will be minted on the Ethereum blockchain.</p>
                            <p className="p-2">This mechanism is best suited to determine who owns what, and the ownership of these NFTs will become a genuine argument in future disputes over the commercial use of asteroids.</p>
                            <p className="p-2"><b>B. Promoting the property rights of our members.</b></p>
                            <p className="p-2">The use of blockchain to represent registrable physical assets is not new, but as property registries are often maintained by states, it has to date been little more than a mere suggestion. However, in the case of digital assets, the online community has made the NFT a superior tool for registering and transferring ownership without the need for an external organization.</p>
                            <p className="p-2">The self-imposed prohibition on states claiming sovereignty over celestial bodies does not prevent other entities from claiming and recognizing their ownership. It only forces us to consider the private mechanisms that states acknowledge as a source of rights for their citizens. Provided these mechanisms are robust, impartial, offer an efficient solution, and gain community acceptance, they can be successful, but this requires building consensus beyond tacit agreements between NFT owners and the system.</p>
                            <p className="p-2">The CLUB will promote activities and initiatives to raise awareness in the international community of the importance of property rights for the use of space resources and the legitimacy of the claims of its members.</p>
                            <p className="p-2"><b>C. Support for research and development projects.</b></p>
                            <p className="p-2">Resource hunting, robotics, material extraction, logistics, rocketry, computer science, space assembly, telecommunications, artificial intelligence, fuel procurement and supply, vital support, and more.</p>
                            <p className="p-2">The technologies that will enable asteroid exploration and exploitation require the success (and failure) of initiatives of all types. The CLUB can contribute its resources to developing an ecosystem that participates in all kinds of ventures and leads to technological advances that enable the commercial exploitation of asteroids.</p>
                            <p className="p-2">There are countless mechanisms to make this possible: partnerships with universities, research centers, and private companies, to the direct presentation of projects, subject to public scrutiny and validated by CLUB members with their vote.</p>
                            <p className="p-2">Each party must recognize, as a fundamental requirement, the legitimacy of the CLUB members' claims.</p>
                            <p className="p-2"><b>D. Investment in exploration and exploitation-related projects.</b></p>
                            <p className="p-2">Later, ABC may participate as a venture capitalist in space exploration and exploitation projects by purchasing the project's tokens with its funds or by minting tokens that give members priority or/and advantageous terms in their distribution.</p>
                            <p className="p-2"><em>All activities that the CLUB may subsidize, fund, or invest in require the consent of the CLUB members', but also express acknowledgment of the members' ownership rights to their corresponding asteroids.</em></p>
                            <p className="p-2 fw-bold">Asteroid Belt Club NFT</p>
                            <p className="p-2">Each NFT is a graphic piece with unique properties associated with a token minted on the Ethereum network that represents the first property claim of the corresponding asteroid.</p>
                            <p className="p-2">Graphically, each NFT denotes several aspects of its respective asteroid: primary designation, catalog number, name (if it was named), year of discovery, orbit type, known diameter, estimated economic value (if such an estimate exists), satellites (if it has any), composition and spectral analysis (if it has ever spectrally analyzed), and whether its distance to Earth or Mars is less than 0.5AU before 2150.</p>
                            <p className="p-2">All of this information is sorted and categorized so that NFTs can be easily distinguished from one another while corresponding to digital artworks with attributes that vary in rarity determined by their nature. The values represented by their visual categorization correlate directly with the token's metadata.</p>
                            <p className="p-2">Initially, only 550,000 claims will be accepted, entitling full membership in CLUB. From the first update with the new asteroids numbered by the IAU, this limit will always be 90% of the total.</p>
                            <p className="p-2">If the club agrees to mint additional NFTs representing claims following the guidelines established in the Section "Strategic Reserve," these will not confer CLUB membership or voting rights unless the agreement states otherwise and require that the stipulated fees are payable.</p>
                            <p className="p-2">Initially, claims will be allowed for some of the 605011 asteroids added to the ABC database before 1 January 2022. On 1 March 2023, all asteroids numbered by the IAU between 1 January 2022 and 30 November 2022 will be added. This process will be repeated every three months:
                            <ul>
                                    <li>•	Every 1 March, all asteroids numbered before the previous 31 December are included. </li>
                                    <li>•	Every 1 June, all asteroids numbered before the previous 28 February are included.</li>
                                    <li>•	Every 1 September, all asteroids numbered before the previous 31 May are included.</li>
                                    <li>•	On 1 December, all asteroids numbered before 31 August are included.</li>
                                </ul>
                            </p>
                            <p className="p-2">There are two types of claims: direct claims, where you can choose the asteroid to claim yourself, and random claims, where the asteroid claimed will be randomly determined.</p>
                            <p className="p-2 fw-bold">Categories</p>
                            <p className="p-2">We have classified the original supply of asteroids into six different categories, taking into account: estimated value, type of orbit, diameter, spectral analysis, and their distance when approaching Earth or Mars before 2150.</p>
                            <p className="p-2">Of the first 605011 asteroids added to the ABC database, the following table of numbers shows how many were placed in each category:
                                <ul>
                                    <li>0) 473211</li>
                                    <li>1) 127865</li>
                                    <li>2) 2671</li>
                                    <li>3) 1154</li>
                                    <li>4) 72</li>
                                    <li>5) 38</li>
                                </ul>
                            </p>
                            <p className="p-2">Each asteroid added to the ABC database in the future will go into a new category.</p>
                            <p className="p-2">The categories are intended only as a guide, and their different prices only indicate the need to reflect the emerging scarcity of their differentiation, as we do not know which asteroids will be most valuable. We believe this will depend on how future technologies and resources are developed and exploited.</p>
                            <p className="p-2">Although the information available on individual asteroids may change over time (more precise spectral analysis, estimated value, diameter measurements, etc.), their categories will be set in stone at the time of their inclusion.</p>
                            <p className="p-2">For 0.5 ETH, you can claim a category 0 asteroid. For 1 ETH, you can claim a category-1 asteroid. For 5 ETH, you can claim a category-2 asteroid. For 10 ETH, you can claim a category-3 asteroid. For 40 ETH, you can claim a category-4 asteroid. For 100 ETH, you can claim a category-5 asteroid.</p>
                            <p className="p-2">Considering that initially only 550,000 claims will be allowed and that the average cost of a claim is 0.65 ETH, the maximum potential value of all Claims is 360,000 ETH (approximately 637.8 million USDT as of 8 September 2022).</p>
                            <p className="p-2">The categories also determine the number of votes each member will have in the CLUB DAO. 1 vote for category 0, 2 votes for category 1, 10 votes for category 2, 20 votes for category 3, 80 votes for category 4, and 200 votes for category 5.</p>
                            <p className="p-2 fw-bold">Direct Claims</p>
                            <p className="p-2">On our website, you can claim up to 10 asteroids in a single transaction. There is no limit to the number of transactions or claims you can make from a single wallet. The category dictates the cost of claiming a particular asteroid.</p>
                            <p className="p-2">For example, claiming A802 FA (Pallas) costs 10 ETH, claiming 2010 BN15 costs 1 ETH, and claiming 2014 QZ21 costs 0.5 ETH.</p>
                            <p className="p-2 fw-bold">Random Claims</p>
                            <p className="p-2">You can claim asteroids randomly, so chance decides which ones you claim. We have developed a method that makes these claims random and fair. To make a randomized claim, you first have to choose between 4 different groups that vary in cost,  between 0.05 ETH and 5 ETH. Up to 10 simultaneous claims can be made in the same group.</p>
                            <p className="p-2">After choosing the group and number of asteroids to claim, a list will be made up of 200 asteroids randomly selected from among the unclaimed and "weighted" by category.</p>
                            <p className="p-2">From this list, we will randomly claim the previously selected number of asteroids. The CLUB smart contract will perform this random selection.</p>
                            <p className="p-2">The list can be updated as much as you want, although it expires 120 seconds after being generated.</p>
                            <p className="p-2">Depending on the group chosen, the random list consists of asteroids of different categories:</p>
                            <p className="p-2"><b>Group 1:</b>
                                <ul>
                                    <li>-	Category 0: 154, Category 1: 45, Category 2: 1</li>
                                    <li>-	Median asteroid value: 0.61 ETH</li>
                                    <li>-	Cost per random claim: 0.05 ETH</li>
                                </ul>
                            </p>
                            <p className="p-2"><b>Group 2:</b>
                                <ul>
                                    <li>-	Category 0: 121, Category 1: 73, Category 2: 4, Category 3: 2</li>
                                    <li>-	Median asteroid value: 0.8675 ETH</li>
                                    <li>-	Cost per random claim: 0.095 ETH</li>
                                </ul>
                            </p>
                            <p className="p-2"><b>Group 3:</b>
                                <ul>
                                    <li>-	Category 1: 100, Category 2: 70, Category 3: 28, Category 4: 2</li>
                                    <li>-	Median asteroid value: 4.05 ETH</li>
                                    <li>-	Cost per random claim: 0.95 ETH</li>
                                </ul>
                            </p>
                            <p className="p-2"><b>Group 4:</b>
                                <ul>
                                    <li>-	Category 2: 136 Category 3: 58, Category 4: 4, Category 5: 2</li>
                                    <li>-	Median asteroid value: 8.1 ETH</li>
                                    <li>-	Cost per random claim: 5 ETH</li>
                                </ul>
                            </p>                            
                            <p className="p-2"><b>If a particular list in a group cannot be completed (for example, if there are not enough category-5 asteroids to fill a list in group 4), it will be disabled.</b></p>
                            <p className="p-2">With this conformation of the groups, the maximum possible number of randomized claims is 396900, with a maximum potential income of 67 thousand ETH (Approximately 118.7 million USDT as of September 8, 2022)</p>
                            <p className="p-2">The ABCIgniter and eventually DAO will be able to change both the composition of the groups and their mintage price.</p>
                            <p className="p-2 fw-bold">The Kickstarter program</p>
                            <p className="p-2">The Kickstarter program rewards the enthusiasm of the first to join the Asteroid Belt Club by multiplying the purchasing power of the amount spent by ETH, according to a descending reward scale that depends on the number of funds previously raised.</p>
                            <p className="p-2">The first 50 ETH raised by the program will be multiplied by 8, the next 50 ETH will be multiplied by 4, the next 100 ETH will be multiplied by 3, and the next 300 ETH will be multiplied by 2. The smallest sum of money needed to participate is 0.05 ETH.</p>
                            <p className="p-2">The program will end when 500 ETH is collected, converting into 1500 ETH of purchasing power. But we will continue to allow deposits without a multiplier, to top up small balances and not leave them unused.</p>
                            <p className="p-2">Those participating in the Kickstarter program will receive an account from which they can deduct the cost of their claims and even transfer all or part of their balance to a third party.</p>
                            <p className="p-2 fw-bold">Strategic Reserve</p>
                            <p className="p-2">The Reserve exists so that the CLUB may enter into agreements with governments, institutions, and corporations that result in the recognition of claims of ownership over such asteroids. Each agreement must be proposed and approved following the rules described in the DAO Smart Contract.</p>
                            <p className="p-2">Strategic agreements will deposit a certain amount of ETH into a Special account from which the cost of one or more asteroids can debit. Initially, NFTs representing these claims WILL NOT grant membership in the CLUB. The owners of the NFTs imprinted by a strategic agreement can acquire full membership after paying a fee specified in the Agreement.</p>
                            <p className="p-2">The cost of this fee is part of each agreement, but it must be greater than or equal to the minimum specified in the contract. These minimum costs start at 0.1 ETH and may be changed over time but should always be higher than the previous value.</p>
                            <p className="p-2">All asteroids can be minted through agreements. To avoid possible abuses, the rules are simple. Asteroids Minted with ETH from the accords can never exceed 10% of the total collected until 500,000 asteroids have been minted and the total spent by settlement beneficiaries to mint asteroids can never exceed 10% of the total ETH collected.</p>
                            <p className="p-2 fw-bold">Belter´s Day</p>
                            <p className="p-2">We want every project enthusiast to have the opportunity to claim one of the available asteroids for free. To this end, we have decided to create "Belters' Day" to celebrate the birth of our community with a <b>ticket</b> giveaway.</p>
                            <p className="p-2"><b>How does this work?</b></p>
                            <p className="p-2">Every July 7, a certain number of tickets will be distributed over a series of raffles.</p>
                            <p className="p-2">During the first ten years, the number of tickets decreases annually, then every ten years for the next 30 years, ending at six entries per year for the last 24 years.</p>
                            <p className="p-2">Similarly, the number of raffles every Belter´s Day will also decrease over time: there will be  72 yearly raffles for the first six years, 48 the following 4, 24 the following 10, 16 the following  10, and 12 the following 10, ending at six yearly raffles the final 24 years.</p>
                            <p className="p-2">A total of 10,000 tickets will be drawn</p>
                            <p className="p-2">The first raffle will take place with the first block discovered in the Ethereum network after the first second of every Belters’ Day. Depending on the number of daily raffles, the following will occur every N block, N being the result of dividing 5760 (The average number of new blocks per day) over the number of raffles. The first six years will have a raffle every 80  blocks, while the last ten years will have a raffle every 960 blocks.</p>
                            <p className="p-2"><em>The results are officially calculated 4 hours after the end of the last draw</em>.</p>
                            <p className="p-2"><b>How to participate:</b></p>
                            <p className="p-2">To participate, you must register an account on beltersday.com. Only one registration per Wallet is allowed. The participant must also provide a valid email address to which the winners will receive the code to claim their prize. This code is ONLY redeemable for the registered Wallet and is not transferable.</p>
                            <p className="p-2">Only one Wallet will be allowed per email address and only two registrations from the same IP address per hour. These measures intend to discourage unfair practices and promote fair play between all participants.</p>
                            <p className="p-2">Sign-ups can be made at any time, but only those who registered before 23:00 GMT on July 6 will participate. Subsequent registrations will qualify in next year's draw.</p>
                            <p className="p-2">You have to re-register every year because we reset the list every year.</p>
                            <p className="p-2">After the registrations are closed and before the raffles begin, the list will be uploaded to IPFS and notarized on the blockchain to ensure total process transparency. Participants can download the complete list of participating lucky numbers at any time.</p>
                            <p className="p-2"><b>Lucky Numbers</b></p>
                            <p className="p-2">During registration, each participant receives a lucky number. This number is the CRC32 of the chain, which is the hexadecimal value of the address of registered Wallet address.</p>
                            <p className="p-2">In addition, if you use a valid reference code when registering, one extra lucky number is generated. This consists of the CRC32 of the concatenation of the chains that represent the hexadecimal value of the address of the registered Wallet and the chain that represents the hexadecimal value of the address of the Wallet of the owner of the referral code.</p>
                            <p className="p-2"><b>The Referral Program</b></p>
                            <p className="p-2">Each participant will receive a code that will allow them to participate in the referral program, spread the word about Belters Day, and receive an additional lucky number for each participant who signed up with their referral code. The new participant will also receive an extra Lucky number.</p>
                            <p className="p-2"><em>How does the raffle work?</em></p>
                            <p className="p-2">The lucky number of each raffle is calculated by computing the unsigned integer value of the CRC32 of the concatenation of the chains representing the hexadecimal value of the hash of the block, the hexadecimal value of the address of the Wallet of the discovering miner and its timestamp.</p>
                            <p className="p-2">The winners are all participants whose lucky number is equal to or higher than the lucky number of the block of the raffle, in order from the lowest to the highest value, until allocating all lots. If two participants have the same lucky number, the one who registered earlier will have priority. If there are any tickets left, they will be added to the next raffle. If there are any tickets left after the last raffle of Belter's Day, they will be added to the first raffle of the next Belter's Day.</p>
                            <p className="p-2"><b>Claiming Asteroids with tickets</b></p>
                            <p className="p-2">From the moment a winner is calculated, they'll have 2.160.000 blocks(Approximately 300  days) to exercise their right to claim the property of an asteroid for free. The winner will receive a code in their email that will allow them to make their claim free of cost if they go to <a href="www.asteroidbeltclub.com" target="_blank">www.asteroidbeltclub.com</a>  and go through the process of minting the ERC721 token on the Ethereum blockchain. If the winning tickets have not been redeemed after the deadline, they will be included again in the first draw of the next Belter's Day.</p>
                            <p className="p-2 fw-bold">Asteroid Naming Service</p>
                            <p className="p-2">It is a service that will be available in the second half of 2023.</p>  
                            <p className="p-2">Will allow the holder of a claim on an asteroid (not named by its discoverer) to baptize an asteroid, according to current IAU guidelines.</p>
                            <p className="p-2">We will enter this name in the CLUB database, and a new version of the asteroid NFT will be minted to reflect this change. Renaming of asteroids is not allowed.</p>
                            <p className="p-2">ANS will charge a small fee for the service. We will divide by two the remaining revenues. One half we will offer to the IAU in support of their activity, with the condition of recognizing the name in their documents and the Minor Planet Center database. The other half will go to the Club treasury.</p>  
                            <p className="p-2 fw-bold">The Asteroid Belt Club Igniter</p>
                            <p className="p-2">The Asteroid Belt Club Igniter is the team responsible for the design, development, and launch of the project detailed in this document. Its goal is to organize the launch of CLUB, to develop and implement the technologies that will transform it into a decentralized autonomous organization, to custodian the assets of CLUB until the DAO manages the asteroids, Develop and implement naming service and propose new projects to CLUB members.</p>
                            <p className="p-2">At the time of the release of the CLUB smart contract, the ABCI claims ten random asteroids for itself as well as 1000 ETH in purchasing power. It also claims 30% of all income from initial entitlements, future transfers, and revenue from entitlement updates resulting from strategic agreements.</p>
                            <p className="p-2">We will dedicate these funds to the maintenance and development of the CLUB's infrastructure, the promotion of its activities, the incorporation of human resources that are considered valuable to the project, and the return to the founders of their initial capital investments.</p>
                            <p className="p-2">Its management is private and is in charge of its founders.</p> 
                            <p className="p-2 fw-bold">Project Roadmap</p>
                            <p className="p-2">The steps laid out for the development and launch of the Asteroid Belt Club are subject to  the following schedule:
                                <ul>
                                    <li>May 2021 – Project’s initial design.</li>
                                    <li>October – December 2021 – Data collection and Database development. January – February 2022 – Designing the NFTs.</li>
                                    <li>January – February 2022 – Designing the NFTs.</li>
                                    <li>February 2022 – NFT production commences.</li>
                                    <li>February – April 2022 – Smart Contract development.</li>
                                    <li>April – July 2022 – Website development.</li>
                                    <li>September – October 2022 – The CLUB launches: Smart Contract is published, and websites are enabled.</li>
                                    <li>March 1st, 2023 – First update to the Database and production of additional NFTs (From this point on, done every trimester).</li>
                                    <li>July 7th, 2023 – First Belter´s Day!</li>
                                    <li>September 2023 – ANS launches.</li>
                                    <li>August – December 2024 – DAO development.</li>
                                    <li>March 2025 – the DAO is launched.</li>
                                    <li>August 2025 – We will transfer all vault and agreement authority to the DAO.</li></ul></p>
                        </div>
                        <div className="col-md-4 xs-hide"></div>
                    </div>
                    <div className="col-md-12">
                    </div>

                </div>
            </div>
            <Stats main="1" web3={props.web3} />
        </section>

    );
}
export default Whitepaper;