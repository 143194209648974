import axios from "axios";
import { useState, useEffect } from 'react';
import WaitModal from "./WaitModal";
import { APIURL } from '../config';

function ProceedClaiming(props) {
    const [ksBalance,setKsBalance]=useState(0);
    const [useKsBalance,setUseKsBalance]=useState(false);
    const expectedBlockTime = 1000;
    const [wait,setWait]=useState({"show":false});
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    function goToWallet(block) {
        window.location.assign('/recent?block='+block);
    }

    function checkout() {
        let referer=localStorage.getItem('referer');
        const claim=localStorage.getItem('stored');
        axios.post("https://"+APIURL+"/claim/asteroid", {
            wallet: props.account,
            claim: claim,
            referer: referer,
        })
        .then(function(response) {
            console.log(response.data.data);
            const mintData=response.data.data;
            let value=mintData.cost;
            if(useKsBalance===true && ksBalance >= Number(props.web3.utils.fromWei(value, 'ether'))) value=0;
            mint(mintData.referer,mintData.ids,mintData.cost,mintData.msgHash,mintData.signature,value);
        })
        .catch(function(error) {
            props.alert(error.response.data.message,'danger',6000);
        });
    }

    async function mint(referer,ids,cost,msgHash,signature,value) {
        try {
            props.alert('Please wait while check Gas Price', 'info', 6000);
            props.contract.methods.mint(props.account,referer,ids,cost,msgHash,signature).estimateGas({ from: props.account, value: value}, function(error, estimatedGas) {
                if(error!==null) {
                    let errore = error.toString() 
                    if (errore.indexOf('Internal JSON-RPC error.') > -1) {
                          errore = errore.replace('\n', '').replace("Error: ", '').replace('Internal JSON-RPC error.', '')
                          error = JSON.parse(errore)
                    }
                    console.log(error);
                    props.alert(error.message,'danger',6000);
                }
                else {
                    props.contract.methods.mint(props.account,referer,ids,cost,msgHash,signature).send({from: props.account ,value: value},async (error, transactonHash) => {
                    if(error!==null) {
                        let errore = error.toString() 
                        if (errore.indexOf('Internal JSON-RPC error.') > -1) {
                                errore = errore.replace('\n', '').replace("Error: ", '').replace('Internal JSON-RPC error.', '')
                                error = JSON.parse(errore)
                        }
                        console.log(error);
                        props.alert(error.message,'danger',6000);
                    
                    }
                    else {
                        let transactionReceipt = null;
                        const message="Please wait until network confirm your transaction";
                        setWait(wait => ({"show":true, "title":"Please wait for Transaction Confirm","message": message}))
                        while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
                            try {
                                transactionReceipt = await props.web3.eth.getTransactionReceipt(transactonHash);
                                await sleep(expectedBlockTime);
                            }
                            catch(error) {
                                props.alert(error.message,'danger');
                            }
                            
                        }
                        setWait(wait => ({"show":false}));
                        if(transactionReceipt.status===true) {
                            console.log(transactionReceipt);
                            const message='Transaction Hash: '+transactionReceipt.transactionHash+' Gas used'+transactionReceipt.gasUsed.toString();
                            props.alert(message,'success',3000);
                            await sleep(3000);
                            props.alert('Wait please','warning',5000);
                            await sleep(5000);
                            props.clear();
                            goToWallet(transactionReceipt.blockNumber);
                        }
                        else {
                            props.alert("An error occur. Transaction reverted",'danger',6000);
                        }
                    }
                });
                        
                        
                }

            })
        }
        catch(error) {        
            props.alert(error.message,'danger');
        }
    }
    
    useEffect(() =>{
        if(props.connected===1) {
            props.contract.methods.kickStarters(props.account).call(function (err, res) {
                if (err) {
                    props.alert("An error occured getting info","danger");           
                    return              
                }
                if(res===0) setUseKsBalance(false);
                else setUseKsBalance(true)
                setKsBalance(Number(props.web3.utils.fromWei(res, 'ether')));
              });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.connected, props.account])

    if(props.connected===1) {
        if(props.items.length >0) {
            return (
                <>           
                <WaitModal show={wait.show} title={wait.title} message={wait.message} />         

                    <div className="col-md-12 text-center pb-4">
                        <h4 className="text-white">Total Estimated {props.total} ETH +gas</h4>
                        { (ksBalance >=props.total) ? (
                                <>
                                <input className="form-check-input" type="checkbox" name="useKB" id="useKB" defaultChecked={useKsBalance} onChangeCapture={() => setUseKsBalance(!useKsBalance)} /> Use kickstart available balance ( {ksBalance} ETH )<br/>
                                </>
                                
                            ):(
                                    <>Your KickStart balance <strong>({ksBalance} ETH)</strong> isn't enough to pay this claim</>
                            )}                                
                                                             
                    </div>
                    <div className="col-md-12 d-flex justify-content-center">                                
                                { props.total >0 && (
                                    <>
                                    <button className="btn-main fs-6 text rounded-0"  onClick={() => checkout()}>Checkout</button>&nbsp;
                                    <button className="btn-black fs-6 text rounded-0 border-0" onClick={() => props.clear() }>Clear cart</button>
                                    </>
                                )}
                    </div>  
                </>
            )
        }

    }
    else {
        return (
            <div></div>
        )
    }
}
export default ProceedClaiming;