import Stats from "../components/Stats";

function Manifesto(props) {
    return (
        <section className="jumbotron no-bg l-vh pb-4" style={ { backgroundImage: `url(${'./img/bg_manifiesto.jpg'})`, backgroundRepeat: 'no-repeat'}}>
             <div className="container"  >
                <div className="row align-items-center">
                    <div className="col-md-8">
                        <div className="text-left mt-5">
                            <h1 className="fw-bold text-black mb-0 mt-4">Asteroid Belt Club</h1>
                            <h2 className="fw-bold text-black-50">Manifesto</h2>
                            <p className="p2 text-black">PDF Notarized version <a href="https://nft.asteroidbeltclub.com/ipfs/QmSszoKfiu7quLnQVwd5aCNhCGfwMRp6cmZx6Q2r5Lz4BA" target="_new">Download Here</a></p>

                        </div>                
                    <div className="rounded p-4 bg-black bg-opacity-50 overflow-scroll mb-5 mt-4 text-light" style={ {minHeight: '66vh'}}>

                    <p className="p-2">Since its origins, two paradigms have divided humanity: The paradigm of scarcity, where natural restrictions dictated by our environment become impenetrable barriers that determine our possibilities. And the paradigm of expansion, where limits are nothing more than obstacles to overcome with ingenuity and audacity.</p>
                    <p className="p-2">Whenever the paradigm of expansion has prevailed, we have managed to make enormous leaps forward as a species. But every time scarcity has been prevalent, suffered stagnation, hunger, and death.</p>
                    <p className="p-2">Long ago, we used to be nothing more than primates, afraid of beasts, fighting to survive in the African savannah. If the scarcity paradigm had prevailed, we’d have stopped reproducing and started killing most of our offspring, sacrificing the less fit and limiting our growth to what the available resources would allow us. Yet, some people had the guts to look beyond our limits, expand our horizons and take us where nobody had ever been before.</p>
                    <p className="p-2">Of course, every new frontier comes with new challenges. We discovered that rocks were natural resources that could be sharpened and turned into weapons and that those weapons could transform the beasts into food and clothing. We gained control over fire and became able to survive hostile climates. Despite our continuous expansion into decreasingly fertile lands, we're capable of inhabiting almost every corner of our planet.</p>
                    <p className="p-2">While some romanticize hunter-gatherer humanity, the discovery of agriculture and settling down in villages allowed us to grow beyond a few tens of thousands of people living on Earth. Through planting and harvesting, we provided ourselves with food and the possibility of forming larger and larger communities where the division of labor was possible, discovered trade, and formed the basis of our first societies.</p>
                    <p className="p-2">Of course, our evolutionary process has always been in question as our growth as a species has not been linear. Throughout history, the paradigm of scarcity has led us to believe that plagues, famines, and war are the only possible escape from the depletion of our planet’s natural resources. Even has history keeps proving over and over again that those approaches will never be the solution, many still warn us today of the need to downsize our species by claiming that limiting our consumption and comfort is the only alternative to ecological disaster.</p>
                    <p className="p-2">However, our levels of prosperity keep increasing, more and more people get lifted out of poverty, and today poor people live better than they did a century ago. And while it’s true that Earth’s natural resources are being depleted, we know that if we don’t fall back into the paradigm of scarcity and instead search for new alternatives, explore new horizons and throw ourselves into new adventures, we’ll be able to fight the threat of a demographic explosion, prevent the depletion of fossil fuels, and manage to avoid the so-called environmental cataclysm.</p>
                    <p className="p-2">Mother Earth might be exhausted, but humanity is far from the limits of its expansion. Technological progress will allow us to discover new resources and alternatives to improve the environment and the quality of life of all our inhabitants, especially that of the most vulnerable. We believe that by raising our heads to the night sky, we can find the solution to humanity's biggest problems.</p>
                    <p className="p-2">What is the point of terrorizing ourselves with a future of misery and scarcity when we have so much available?</p>
                    <p className="p-2">Those distant worlds might seem unreachable, but we are much closer to being able to exploit their resources - even to calling them home - than where our ancestors were to leaving Africa and conquering the planet. What today might seem like science fiction material in a few years will be a reality. While Zénobe Gramme invented the first electrical generator, Jules Verne wrote: “From the Earth to the Moon” in 1865. One century later, not only had electrical energy completely transformed our lives, but we had been able to land on the moon.</p>
                    <p className="p-2">Today, even when there already are potential homes for our species orbiting our planet, and even when billions of humans are connected to a web of global reach where they have access to information and entertainment that barely existed 30 years ago, even when we are planning a definitive return to the moon and designing ways to live in Mars, a feat like the exploitation of mineral resources available in the Asteroid Belt remains a fiction.</p>
                    <p className="p-2">There are many reasons why we should embark upon asteroid mining. Many contain minerals like gold, platinum, nickel, iron, or zinc, and extracting them by developing the appropriate technologies could become simpler and more profitable than it is on our planet.</p>
                    <p className="p-2">On the other hand, expanding into space will require the previously mentioned resources, plus many others (water, vital support, fuel, etc.) that are widely available there and are extremely expensive to bring from here. It makes no sense to continue to damage our planet's ecosystem when we can obtain these resources elsewhere, even with greater efficiency.</p>
                    <p className="p-2">Seeing asteroids as Deep-Impact-like threats and not as an opportunity is another manifestation of that mentality of scarcity that postpones our collective development. We can also evidence this restricted view from the “Asteroid Day” commemorating the 1908 Tunguska incident to so many initiatives that focus on the disaster movie scenario while continuing to be blind to the benefits that asteroid mining could bring us.</p>
                    <p className="p-2">The opportunity is there for us. As Neil deGrasse Tyson said: “The first trillionaire in the world will be the person who mines asteroids.”. Pioneers such as Eric Anderson, Peter Diamandis, David Gump, and Ian Webster have taken forward commercial ventures that will make it possible in the future. The difficulty in obtaining risk capital for activities with uncertain odds of success in the long term has made it impossible for those initiatives to become active projects.</p>
                    <p className="p-2">All nations have agreed not to make claims of sovereignty over celestial bodies, and therefore their laws and registries of property are incapable of establishing ownership. Without property, asteroids will be subject to the “tragedy of the commons” damage perfectly described by Garret Hardin. Without a mechanism that allows us to assign property over asteroids, their rational and peaceful exploitation won’t be possible, making it very difficult to find an incentive to launch projects that will lead to the necessary technology.</p>
                    <p className="p-2">Some uphold the idea of countries financing this activity until it becomes affordable and lay their hopes in the success of the Rosetta/Philae, Dawn, and Hayabusa missions. Or in the Luxembourg and Portuguese government initiatives, and it is possible that these or other state-funded initiatives will have a significant impact. But experience tells us that private activity and its search for profit is the main engine for innovation and what will finally make a difference.</p>
                    <p className="p-2">Private investors willing to risk their resources have the threat of failure and the prospect of extraordinary revenue pushing them even when the odds of success are very low. That impulse is already present in the space industry, and the dramatic drop in the cost of transporting goods and people into space is one of its consequences. We have a great opportunity up there in the stars, and asteroids are a critical piece to seize it. Our goal is to achieve it.</p>
                    <p className="p-2">That is why we have created the Asteroid Belt Club, an initiative for the assignation of property rights over asteroids and their resources, a mechanism by which we can promote the initiatives that will make asteroid mining possible. That is why we are rallying everyone, from the little enthusiasts and investors to potential big players in this future industry. Together we can drive humanity towards a new frontier.</p>
                    
                    </div>
                    <div className="col-md-5 xs-hide"></div>
                    </div>
                    <div className="col-md-12">
                    </div>
                
            </div>
        </div>
        <Stats main="1" web3={props.web3}/>
    </section>
      
    );
}
export default Manifesto;